import React, {useEffect} from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import Chip from 'components/PeriodSelect/Chip'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {Country} from 'domain/ILocation'
import {getAllowedCountries, getHumanReadableCountry} from 'helpers/location'
import useAlerts from 'hooks/useAlerts'
import {Grid, Tooltip} from '@mui/material'

interface IProps {
  countries?: Country[]
  setCountries?: (countries: Country[]) => void
  disabledCountries?: Country[]
}

export const CountriesFilter: React.FC<IProps> = ({countries, setCountries, disabledCountries}) => {
  const {addError} = useAlerts()
  const {translate} = useLocalization()
  const theme = useTheme()
  const allowedCountries = getAllowedCountries()

  const handleCountryClick = (country: Country) => {
    if (countries.includes(country)) {
      setCountries(countries.filter(c => c !== country))
    } else {
      if (countries.length < 5) {
        setCountries([...countries, country])
      } else {
        addError(translate('You can select up to %s countries', 5))
      }
    }
  }

  return (
    <Box align="center" gap={1} margin={{vertical: 2}} style={{minHeight: '32px'}}>
      <Box>
        <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
          {translate('Market')}
        </Text>
      </Box>

      <Grid container spacing={1}>
        {allowedCountries.map(country => {
          if (disabledCountries.includes(country)) {
            return (
              <Tooltip key={country} title={translate('This country is currently not available')}>
                <Grid item xs="auto" key={country}>
                  <Chip
                    disabled
                    onClick={() => {}}
                    label={getHumanReadableCountry(country, translate)}
                    selected={countries.includes(country)}
                  />
                </Grid>
              </Tooltip>
            )
          } else {
            return (
              <Grid item xs="auto" key={country}>
                <Chip
                  onClick={() => handleCountryClick(country)}
                  label={getHumanReadableCountry(country, translate)}
                  selected={countries.includes(country)}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    </Box>
  )
}
