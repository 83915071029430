import IContract, {ContractVolumeProfile, IBuyerContract, ISellerSignedContract} from '../domain/IContract'
import IContractItem from '../domain/IContractItem'
import dayjs from 'dayjs'
import IProductType, {ProductTypeBehaviour, VolumeType} from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITranslate from '../interfaces/ITranslate'
import IProduct from '../domain/IProduct'
import {isProductOnSale} from './product'
import {ResourceId} from '../types'
import {getMaximumDate, getMinimumDate, getYear, toDate} from './date'
import {TransactionType} from '../domain/IContractParty'
import {stringifyUnit} from './price'
import {StateOfAustralia} from '../domain/ILocation'
import {formatEnergy, formatPercentage} from './format'
import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'
import {IExpenditure, isValidMonth, returnEmptyMonth} from './expenditure'
import {isEmpty, find} from 'lodash-es'
import {ITimeseriesOverview} from 'domain/Portfolio'
import isBetween from 'dayjs/plugin/isBetween'
import ITimeseriesItem from 'domain/ITimeseriesItem'

dayjs.extend(isBetween)

const PRODUCTION_TECHNOLOGY_ORDER = {
  [SiteProductionTechnology.SOLAR]: 1,
  [SiteProductionTechnology.HYDRO]: 2,
  [SiteProductionTechnology.WIND]: 3,
  UNKNOWN_TECHNOLOGY: 4,
}

const VOLUME_TYPE_ORDER = {
  [VolumeType.USER_SELECT]: 1,
  [VolumeType.STANDALONE_USER_SELECT]: 2,
  [VolumeType.AUTO_FILL]: 3,
  [VolumeType.NONE]: 4,
  UNKNOWN_VOLUME_TYPE: 5,
}

export const EMISSION_FACTOR = {
  [StateOfAustralia.NSW]: 0.81,
  [StateOfAustralia.VIC]: 1.02,
  [StateOfAustralia.QLD]: 0.81,
  [StateOfAustralia.SA]: 0.44,
  [StateOfAustralia.TAS]: 0.15,
  [StateOfAustralia.WA]: 0.65,
}

export const DEFAULT_EMISSION_FACTOR = 0.9

export function getContractItemVolumeMwh(contractItem: IContractItem): number {
  return contractItem.volumeMwh
}

export function getPercentageOfProduction(contractItem: IContractItem): number {
  if (
    contractItem.volumeMwh &&
    contractItem.product?.site?.avgYearlyProductionMwh &&
    contractItem.product?.site?.avgYearlyProductionMwh > 0
  ) {
    return formatPercentage(contractItem.volumeMwh / contractItem.product.site.avgYearlyProductionMwh)
  }
}

export function getRatioOfProduction(contractItem: IContractItem): number {
  if (
    contractItem.volumeMwh &&
    contractItem.product?.site?.avgYearlyProductionMwh &&
    contractItem.product?.site?.avgYearlyProductionMwh > 0
  ) {
    return +(contractItem.volumeMwh / contractItem.product.site.avgYearlyProductionMwh)
  } else {
    return 0
  }
}

export function getAmountOfProduction(contractItem: IContractItem): number {
  if (contractItem.product?.site?.avgYearlyProductionMwh && contractItem.product?.site?.avgYearlyProductionMwh > 0) {
    return contractItem.product.site.avgYearlyProductionMwh
  }
}

export function getContractVolumeMwhByVolumeType(
  contract: IContract | IPurchaseContract | IContractItem[],
  volumeType: VolumeType,
): number {
  const contractItems = Array.isArray(contract) ? contract : contract?.contractItems || []

  return contractItems
    .filter(contractItem => contractItem.product.productType.volumeType === volumeType)
    .map(contractItem => contractItem.volumeMwh)
    .reduce((acc, current) => acc + current, 0)
}

export function getProductVolumeMwh(productId: any, contract: IContract): number {
  const contractItem = contract?.contractItems?.find(contractItem => contractItem.product.id === productId)

  return contractItem?.volumeMwh || 0
}

export function getContractDurationInYears(contract: IContract, precise = false): number {
  if (!contract.validFrom || !contract.validTo) {
    return null
  }

  const years = dayjs(contract.validTo).diff(contract.validFrom, 'years', true)

  return precise ? years : Math.round(years)
}

export function getContractDurationInMonthsForYear(contract: IContract, year: number): number {
  if (!contract.validFrom || !contract.validTo || !year) {
    return null
  }

  if (getYear(contract.validFrom) === year) {
    const end = dayjs(contract.validFrom).endOf('year')
    return dayjs(end).diff(contract.validFrom, 'month') + 1
  }

  if (getYear(contract.validTo) === year) {
    const start = dayjs(contract.validTo).startOf('year')
    return dayjs(contract.validTo).diff(start, 'month') + 1
  }

  return null
}

export function getContractItemsByProductVolumeType(
  contract: IContract | IPurchaseContract,
  volumeType: VolumeType,
): IContractItem[] {
  return (contract.contractItems || []).filter(
    contractItem => contractItem.product?.productType?.volumeType === volumeType,
  )
}

export function getContractItemsByProductBehaviour(
  contract: IContract,
  behaviour: ProductTypeBehaviour,
): IContractItem[] {
  return (contract.contractItems || []).filter(
    contractItem => contractItem.product?.productType?.behaviour === behaviour,
  )
}

export function getContractItemsByProductBehaviours(
  contract: IContract,
  behaviours: ProductTypeBehaviour[],
): IContractItem[] {
  return (contract.contractItems || []).filter(contractItem =>
    behaviours.includes(contractItem.product?.productType?.behaviour),
  )
}

export function getContractItemsByProductBehaviourAndVolumeType(
  contract: IContract,
  behaviour: ProductTypeBehaviour,
  volumeType: VolumeType,
): IContractItem[] {
  return (contract.contractItems || []).filter(contractItem => {
    const productType = contractItem.product?.productType

    return productType?.volumeType === volumeType && productType?.behaviour === behaviour
  })
}

export function volumeTypeExistsInContract(contract: IContract, volumeType: VolumeType): boolean {
  return contract.contractItems?.some(contractItem => contractItem.product?.productType?.volumeType === volumeType)
}

export function volumeTypeExistsInContracts(contracts: IContract[], volumeType: VolumeType): boolean {
  return contracts.some(contract => volumeTypeExistsInContract(contract, volumeType))
}

export function getContractsPeriod(contracts: IContract[]): [string, string] {
  return [
    getMinimumDate(...contracts.map(c => c.validFrom)) as string,
    getMaximumDate(...contracts.map(c => c.validTo)) as string,
  ]
}

export function getProductTypesOfContracts(contracts: (IContract | IPurchaseContract)[]): IProductType[] {
  const productTypes: {[key: string]: IProductType} = {}

  contracts.forEach(contract => {
    contract.contractItems?.forEach(contractItem => {
      const productType = contractItem.product?.productType

      if (productType) {
        productTypes[productType.id] = productType
      }
    })
  })

  return Object.values(productTypes)
}

export function getProductTypesOfContract(contract: IContract): IProductType[] {
  return getProductTypesOfContracts([contract])
}

export function getHumanReadableContractVolumeProfile(profile: ContractVolumeProfile, translate: ITranslate): string {
  switch (profile) {
    case ContractVolumeProfile.GENERATION_FOLLOWING:
      return translate('Generation following')
    case ContractVolumeProfile.LOAD_FOLLOWING:
      return translate('Load following')
    case ContractVolumeProfile.FLAT:
      return translate('Flat')
  }

  return profile
}

function getProductionTechnologyOrder(contractItem: IContractItem): number {
  return (
    PRODUCTION_TECHNOLOGY_ORDER[contractItem.product?.site?.productionTechnology] ||
    PRODUCTION_TECHNOLOGY_ORDER.UNKNOWN_TECHNOLOGY
  )
}

export function getVolumeTypeOrder(contractItem: IContractItem) {
  return VOLUME_TYPE_ORDER[contractItem.product.productType.volumeType] || VOLUME_TYPE_ORDER.UNKNOWN_VOLUME_TYPE
}

export function sortContractItems(contractItems: Array<IContractItem>): Array<IContractItem> {
  return [...contractItems].sort(
    (a, b) =>
      getProductionTechnologyOrder(a) - getProductionTechnologyOrder(b) ||
      getVolumeTypeOrder(a) - getVolumeTypeOrder(b),
  )
}

export function getChartContractItems(contractItems = []): Array<IContractItem> {
  const filteredItems = contractItems.filter(
    item => !!item.product?.site?.timeseriesId && !!item.product?.totalVolumeForSaleMwh,
  )
  return sortContractItems(filteredItems)
}

export function isRemovable(id: number, mandatoryProducts: IProduct[]) {
  return mandatoryProducts.findIndex(product => product.id === id) === -1
}

export function isAnyContractProductExpired(contractItems: Array<IContractItem>) {
  return contractItems.some(item => !isProductOnSale(item.product))
}

export function filterContractsBySiteId(contracts: IContract[], siteId: ResourceId): IContract[] {
  return contracts.filter(contract => {
    return contract.contractItems.some(item => item.product?.site?.id === siteId)
  })
}

export function filterContractsByPeriod(contracts: IContract[], start: Date, end: Date): IContract[] {
  return contracts.filter(contract => {
    const validFrom = toDate(contract.validFrom)
    const validTo = toDate(contract.validTo)

    return (
      dayjs(start).isBetween(validFrom, validTo) ||
      dayjs(end).isBetween(validFrom, validTo) ||
      dayjs(validFrom).isBetween(start, end) ||
      dayjs(validTo).isBetween(start, end)
    )
  })
}

export function getContractYearlyVolumeMwh(contract: IContract | IPurchaseContract) {
  return +contract.volumeMwh || +contract.summary?.volumeMwh
}

export function getContractsTotalVolumeMwh(contracts: IContract[]) {
  return contracts.reduce((acc, current) => {
    return acc + getContractYearlyVolumeMwh(current)
  }, 0)
}

export function getContractsAveragePrice(contracts: IContract[]) {
  const totalConsumption = getContractsTotalVolumeMwh(contracts)
  let total = 0
  contracts.forEach(contract => {
    let perContractTotal = 0
    contract?.contractItems.forEach(item => {
      if (
        item.product.productType.behaviour === ProductTypeBehaviour.ELECTRICITY ||
        item.product.productType.behaviour === ProductTypeBehaviour.BALANCING
      ) {
        perContractTotal +=
          (item.volumeMwh / contract.volumeMwh) *
          (item.productPriceValue ? item.productPriceValue : item.product.price?.value || 0)
      }
    })
    total += perContractTotal * (contract.volumeMwh / totalConsumption)
  })

  return total
}

export function getContractsVolumeMwhByTechnology(contracts: ISellerSignedContract[]): {
  [key in SiteProductionTechnology]?: number
} {
  const result: {[key in SiteProductionTechnology]?: number} = {}

  contracts?.forEach(contract => {
    const productionTechnology = contract?.site?.productionTechnology

    if (!productionTechnology) {
      return
    }

    if (!result[productionTechnology]) {
      result[productionTechnology] = 0
    }

    result[productionTechnology] += contract.volumeMwh
  })

  return result
}

export function getPriceOfContractItems(contractItems: IContractItem[]): number {
  return contractItems.reduce((acc, current) => acc + current.price.value, 0)
}

export function getWeightAverageForContractItems(contractItems: IContractItem[]): number {
  if (!contractItems || contractItems.length <= 0) return 0
  let upper = 0
  let lower = 0

  contractItems.forEach(item => {
    upper += item.volumeMwh * (item.productPriceValue ? item.productPriceValue : item.price?.value)
    lower += item.volumeMwh
  })

  return lower > 0 ? upper / lower : 0
}

export function getContractItemsByVolumeTypeAndVisibility(
  contractItems: IContractItem[],
  behaviour: ProductTypeBehaviour,
): IContractItem[] {
  return (
    contractItems &&
    contractItems.filter(
      item => item.product.productType.behaviour === behaviour && item.product.productType.priceVisibleToCustomer,
    )
  )
}

export function getContractItemsByVolumeType(
  contractItems: IContractItem[],
  behaviour: ProductTypeBehaviour,
): IContractItem[] {
  return contractItems && contractItems.filter(item => item.product.productType.behaviour === behaviour)
}

interface ElectricityPrice {
  price: number
  unit: string
}

export function getContractsElectricityPrice(contracts: IContract[]): ElectricityPrice {
  const contractItems: IContractItem[] = []
  let unit: string

  contracts.forEach(contract => {
    contract.contractItems.forEach(contractItem => {
      if (!unit && contractItem.product?.price?.unit) {
        unit = stringifyUnit(contractItem.product?.price?.unit)
      }
      contractItems.push(contractItem)
    })
  })

  return {
    price: getWeightAverageForContractItems(
      getContractItemsByVolumeTypeAndVisibility(contractItems, ProductTypeBehaviour.ELECTRICITY),
    ),
    unit,
  }
}

export function getContractTotalCO2Emission(contract: IContract) {
  const electricityTypeContractItems = getContractItemsByProductBehaviours(contract, [
    ProductTypeBehaviour.ELECTRICITY,
    ProductTypeBehaviour.BALANCING,
  ])

  return electricityTypeContractItems
    .map(contractItem => {
      const siteSubdivision = contractItem.product?.site?.location?.addressSubdivision

      //When Site has no AddressSubdivision with emission factor, use default emissionFactor
      const emissionFactor = EMISSION_FACTOR[siteSubdivision] || DEFAULT_EMISSION_FACTOR
      return contractItem.volumeMwh ? contractItem.volumeMwh * emissionFactor : 0
    })
    .reduce((totalCO2Emission, contractItemCO2Emission) => totalCO2Emission + contractItemCO2Emission, 0)
}

export function getContractLGCVolume(contract: IContract) {
  const lgcItems = getContractItemsByProductBehaviour(contract, ProductTypeBehaviour.LGC)
  return lgcItems.reduce((totalLGCVolume, contractItem) => totalLGCVolume + contractItem.volumeMwh, 0)
}

export function getContractFirmingVolume(contract: IContract) {
  const firmingItems = getContractItemsByProductBehaviour(contract, ProductTypeBehaviour.BALANCING)
  return firmingItems.reduce((totalLGCVolume, contractItem) => totalLGCVolume + contractItem.volumeMwh, 0)
}

export function getContractDirectEnergyVolume(contract: IContract) {
  const lgcItems = getContractItemsByProductBehaviour(contract, ProductTypeBehaviour.ELECTRICITY)
  return lgcItems.reduce((totalLGCVolume, contractItem) => totalLGCVolume + contractItem.volumeMwh, 0)
}

export function getContractDirectEnergyVolumeFromContracts(contracts: IContract[]) {
  let total = 0
  contracts.forEach(contract => {
    total += getContractDirectEnergyVolume(contract)
  })
  return total
}

export function getGreenCoverageIndicator(contracts: IContract[], yearlyConsumptionMwh: number): number {
  // TODO Only works when period is one year
  let totalLGCVolume = 0

  contracts.forEach(contract => {
    // LGC volume includes the firming volume, so we need to subtract it
    totalLGCVolume += getContractLGCVolume(contract)
  })

  return totalLGCVolume / yearlyConsumptionMwh
}

export function getGreenCoverage(expenditure: IExpenditure[]) {
  // TODO Only works when period is one year
  let total = 0
  expenditure.forEach(expend => (total += expend.greenCoverage))

  return total > 0 ? parseInt(((total / 12) * 100).toFixed(0)) : 0
}

export function getGreenCoverageFromContracts(
  contracts: IContract[],
  activePeriod: any,
  totalAvgYearlyConsumptionMwh: number,
) {
  let total = 0

  contracts.forEach(contract => {
    const lgcVolume = getContractLGCVolume(contract)
    const months = getContractDurationInMonthsForYear(contract, parseInt(activePeriod?.slug))

    if (!months) {
      total += (lgcVolume * 100) / totalAvgYearlyConsumptionMwh
    } else {
      total += ((lgcVolume * 100) / totalAvgYearlyConsumptionMwh / 12) * months
    }
  })

  return parseInt(total.toFixed(0))
}

export function getCO2Reduction(contracts: IContract[]): number {
  return contracts
    .map(contract => {
      const siteTotalCO2Emission = getContractTotalCO2Emission(contract)
      const totalLGCVolume = getContractLGCVolume(contract)
      const lgcCoverage = totalLGCVolume / getContractYearlyVolumeMwh(contract)

      return siteTotalCO2Emission * lgcCoverage
    })
    .reduce((totalCO2Reduction, contractCO2Reduction) => totalCO2Reduction + contractCO2Reduction, 0)
}

export function getCO2WithEmissions(
  contracts: IContract[],
  themeEmission: number,
  partyYearlyConsumption: number,
): number {
  let total = 0
  let volume = partyYearlyConsumption

  contracts.forEach(contract => {
    const bundledItems = getContractItemsByProductBehaviourAndVolumeType(
      contract,
      ProductTypeBehaviour.LGC,
      VolumeType.NONE,
    )
    if (bundledItems.length === 0) {
      const lgcItems = getContractItemsByProductBehaviour(contract, ProductTypeBehaviour.LGC)
      lgcItems.forEach(item => {
        if (item.product.emissionFactor !== null) {
          volume -= item.volumeMwh
          total += item.volumeMwh * item.product.emissionFactor
        }
      })
    } else {
      const electricityItems = getContractItemsByProductBehaviour(contract, ProductTypeBehaviour.ELECTRICITY)
      electricityItems.forEach(item => {
        if (item.product.emissionFactor !== null) {
          volume -= item.volumeMwh
          total += item.volumeMwh * item.product.emissionFactor
        }
      })
      const unbundledLgcItems = getContractItemsByProductBehaviourAndVolumeType(
        contract,
        ProductTypeBehaviour.LGC,
        VolumeType.STANDALONE_USER_SELECT,
      )
      unbundledLgcItems.forEach(item => {
        if (item.product.emissionFactor !== null) {
          volume -= item.volumeMwh
          total += item.volumeMwh * item.product.emissionFactor
        }
      })
    }
  })

  total += volume * themeEmission

  return total
}

export function getContractSeller(contract: IPurchaseContract): IPurchaseParty | null {
  return contract?.contractParties?.find(cp => cp.transactionType === TransactionType.SELL)?.party || null
}

export function generateContractTitleValue(contract: IContract | IPurchaseContract | IBuyerContract): string {
  if (contract.name) {
    return `#${contract.id} - ${contract.name}`
  }

  return `#${contract.id}`
}

export function getElectricityItemsAndNotVisibleToCustomers(contractItems: IContractItem[]): IContractItem[] {
  return (
    contractItems &&
    contractItems.filter(
      item =>
        item.product.productType.behaviour === ProductTypeBehaviour.ELECTRICITY ||
        !item.product.productType.priceVisibleToCustomer,
    )
  )
}

export function getNonLgcItems(contractItems: IContractItem[]): IContractItem[] {
  return contractItems && contractItems.filter(item => item.product.productType.behaviour !== ProductTypeBehaviour.LGC)
}

export function getElectricityItemsAndFirmingItems(contractItems: IContractItem[]): IContractItem[] {
  return (
    contractItems &&
    contractItems.filter(
      item =>
        item.product.productType.behaviour === ProductTypeBehaviour.ELECTRICITY ||
        item.product.productType.behaviour === ProductTypeBehaviour.BALANCING,
    )
  )
}

export function calculateFirmingOrAutofillVolumeMwh(contract: IContract | IPurchaseContract): number {
  const userSelectVolume = getContractVolumeMwhByVolumeType(contract, VolumeType.USER_SELECT)

  return Math.max(getContractYearlyVolumeMwh(contract) - userSelectVolume, 0)
}

export function getContractUnbundledLgcVolume(contract: IContract) {
  const lgcItems = getContractItemsByProductBehaviourAndVolumeType(
    contract,
    ProductTypeBehaviour.LGC,
    VolumeType.STANDALONE_USER_SELECT,
  )
  return lgcItems.reduce((totalLGCVolume, contractItem) => totalLGCVolume + contractItem.volumeMwh, 0)
}

export function calculateBundledLgcVolumeMwh(contract: IContract): number {
  const bundledItems = getContractItemsByProductBehaviourAndVolumeType(
    contract,
    ProductTypeBehaviour.LGC,
    VolumeType.NONE,
  )

  if (bundledItems.length > 0) {
    return Math.max(getContractDirectEnergyVolume(contract), 0)
  }

  return 0
}

export function calculateBundledLgcVolumeFromContracts(contracts: IContract[]): number {
  let total = 0

  contracts.forEach(contract => {
    total += calculateBundledLgcVolumeMwh(contract)
  })

  return total
}

export function calculateBundledLgcVolumeFromContractsWithLength(contracts: IContract[], activePeriod: any): number {
  let total = 0

  contracts.forEach(contract => {
    const months = getContractDurationInMonthsForYear(contract, parseInt(activePeriod?.slug))
    if (!months) {
      total += calculateBundledLgcVolumeMwh(contract)
    } else {
      total += (calculateBundledLgcVolumeMwh(contract) / 12) * months
    }
  })

  return total
}

export function adjustContractAutofillVolumes(contract: IContract): IContract {
  const autofillItems = getContractItemsByProductVolumeType(contract, VolumeType.AUTO_FILL)

  // When autofill products exist, recalculate their volumes
  if (autofillItems.length > 0) {
    const autofillVolumeMwh = calculateFirmingOrAutofillVolumeMwh(contract)

    // Split autofill volume between all autofill products
    autofillItems.forEach(item => {
      item.volumeMwh = formatEnergy(autofillVolumeMwh / autofillItems.length)
    })
  }

  return contract
}

export function adjustContractLgcVolumes(contract: IContract): IContract {
  const lgcItems = getContractItemsByProductBehaviourAndVolumeType(contract, ProductTypeBehaviour.LGC, VolumeType.NONE)

  // LGC items volumes won't be split - all 100% of contact volume
  if (lgcItems.length > 0) {
    const lgcVolumeMwh = formatEnergy(calculateBundledLgcVolumeMwh(contract))

    lgcItems.forEach(item => {
      item.volumeMwh = lgcVolumeMwh
    })
  }

  return contract
}

export function adjustContractVolumes(contract: IContract): IContract {
  let result: IContract = adjustContractAutofillVolumes(contract)
  result = adjustContractLgcVolumes(contract)

  return result
}

export function getContractItemsVolume(contractItems: IContractItem[]): number {
  return contractItems.reduce((acc, item) => acc + item.volumeMwh, 0)
}

export function reduceIntervals(items: ITimeseriesItem[][]): ITimeseriesItem[] {
  return items.filter(Boolean).reduce((prev, current) => {
    const copyCurrent = current.map(item => ({...item}))

    // Intervals are sorted in the same order, so we are safe to iterate them.
    for (let idx = 0; idx < copyCurrent.length; ++idx) {
      copyCurrent[idx].value += prev[idx]?.value || 0
    }

    return copyCurrent
  }, [])
}

export function getActivePeriodContractDurationInfo(contract: IContract, activePeriod?: any) {
  const length = getContractDurationInMonthsForYear(contract, parseInt(activePeriod))
  const numberOfMonthsWithNoContract = 12 - length

  let isContractStartYear = false
  let isContractEndYear = false
  if (getYear(contract.validFrom) === parseInt(activePeriod)) {
    isContractStartYear = true
  }
  if (getYear(contract.validTo) === parseInt(activePeriod)) {
    isContractEndYear = true
  }

  return {length, isContractStartYear, isContractEndYear, numberOfMonthsWithNoContract}
}

export function generateCertificateDataFromContracts(
  contracts: IContract[],
  timeseriesOverview: ITimeseriesOverview,
  sites,
  activePeriod,
  translate: ITranslate,
) {
  const siteIdWithTimeseries = {}
  const purchaseGroupIdWithTimeseries = {}
  const activeYear = parseInt(activePeriod?.slug || 0)
  const certificateData = []

  timeseriesOverview?.contracts
    .flatMap(c => c.predicted.sites)
    .forEach(el => {
      const siteId = el.siteId
      siteIdWithTimeseries[siteId] = {...sites[siteId], ...el}
    })

  // timeseries?.purchaseGroups.forEach((element, i) => {
  //   if (element && element.length > 0) {
  //     purchaseGroupIdWithTimeseries[purchaseGroups[i].id] = element
  //   }
  // })

  const monthWithContractData = returnEmptyMonth(translate)

  const getPurchaseGroupVolumeFromTS = (contract, month) => {
    if (contract.purchaseGroup === null) {
      //because no purchasegroup is assigned we use general profile. So we have total party timeseries and contract volume
      //we can get ratio and use that.
      const partyTimeseries = reduceIntervals(timeseriesOverview.party?.predicted?.sites.map(s => s.timeseries))
      if (!isEmpty(partyTimeseries)) {
        return find(partyTimeseries, {name: month})?.value || 0
      }
    } else if (!isEmpty(purchaseGroupIdWithTimeseries[contract.purchaseGroup?.id])) {
      if (!isEmpty(purchaseGroupIdWithTimeseries[contract.purchaseGroup?.id])) {
        return find(purchaseGroupIdWithTimeseries[contract.purchaseGroup.id], {name: month}).value
      }
    } else {
      return 0
    }
  }

  contracts.forEach(contract => {
    contract.contractItems.forEach(item => {
      if (item.product.productType.behaviour === ProductTypeBehaviour.LGC) {
        monthWithContractData.forEach((month, monthNumber) => {
          const isValid = isValidMonth(contract, monthNumber, activeYear)
          if (item.product.productType.behaviour === ProductTypeBehaviour.LGC) {
            if (item.product.productType.volumeType === VolumeType.STANDALONE_USER_SELECT) {
              const ratio = item.volumeMwh / contract.volumeMwh
              const volume = getPurchaseGroupVolumeFromTS(contract, month.name) * ratio
              certificateData.push({
                contractId: contract.id,
                volume: isValid ? volume : null,
                type: 'Unbundled',
                sortMonth: month.name,
                month: month.month,
                name: item.product.productType.name,
              })
            } else if (item.product.productType.volumeType === VolumeType.NONE) {
              const directEnergyVolume = getContractDirectEnergyVolume(contract)
              const ratio = directEnergyVolume / contract.volumeMwh
              const volume = getPurchaseGroupVolumeFromTS(contract, month.name) * ratio
              certificateData.push({
                contractId: contract.id,
                volume: isValid ? volume : null,
                type: 'Bundled',
                sortMonth: month.name,
                month: month.month,
                name: item.product.productType.name,
              })
            }
          }
        })
      }
    })
  })

  return certificateData
}
