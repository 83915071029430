import React, {useEffect} from 'react'
import Page from 'components/Page'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import useLocalization from 'hooks/useLocalization'
import EacPrice from 'components/Platform/Portfolio/MarketIntelligence/EacPrice'
import Box from 'components/Box'
import Text from 'components/Text'
import PeriodSelect from 'components/PeriodSelect'
import {generateYears, getCurrentYear} from 'helpers/date'
import useTheme from 'hooks/useTheme'
import {useAtom, useSetAtom} from 'jotai'
import {periodAtom, PeriodType, QuickLinkValue, resetPeriodAtom} from 'components/PeriodSelect/periodSelect'
import {CountriesFilter} from 'components/Platform/Portfolio/MarketIntelligence/CountriesFilter'
import {Divider} from '@mui/material'
import useTabs from 'hooks/useTabs'
import MarketMap from 'components/Platform/Portfolio/MarketIntelligence/MarketMap'
import SpotPrice from 'components/Platform/Portfolio/MarketIntelligence/SpotPrices/SpotPrice'
import InnerScroll from 'components/InnerScroll'
import {
  rawIntervalAtom,
  aggrIntervalAtom,
  greenTarifCountriesAtom,
  spotCountriesAtom,
  GREEN_TARIFF_DISABLED_COUNTRIES,
} from 'atoms/marketIntelligence'
import GreenTarif from 'components/Platform/Portfolio/MarketIntelligence/GreenTarif/GreenTarif'
import {Country} from 'domain/ILocation'

export const MARKET_TAB_NAME = 'tab.overview'
export enum MarketTab {
  SPOT_PRICE = 'spot-price',
  GREEN_TARIFF = 'green-tariff',
  CO2_PRICE_RE = 'co2-price-re',
  CO2_PRICE_EUA = 'co2-price-eu',
}

const MarketIntelligence: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [spotCountries, setSpotCountries] = useAtom(spotCountriesAtom)
  const [greenTarifCountries, setGreenTarifCountries] = useAtom(greenTarifCountriesAtom)
  const setRawInterval = useSetAtom(rawIntervalAtom)
  const setAggrInterval = useSetAtom(aggrIntervalAtom)
  const setPeriod = useSetAtom(periodAtom)
  const {currentTab} = useTabs(MARKET_TAB_NAME)
  const theme = useTheme()
  const resetPeriod = useSetAtom(resetPeriodAtom)
  const isEacTab = false
  const isGreenTarifTab = currentTab === MarketTab.GREEN_TARIFF

  const dividerStyles = {
    borderColor: theme.colors.outline,
  }

  useEffect(() => {
    setPeriod({period: PeriodType.QUICK_LINK, value: QuickLinkValue.YESTERDAY})

    return () => resetPeriod()
  }, [])

  return (
    <Page
      title={translate('Market overview')}
      description={translate('Key facts, Aggregated view of markets')}
      gridTemplateColStyle="auto 35%"
      aside={
        isEacTab ? undefined : (
          <Box style={{position: 'relative', height: '100%'}}>
            <Box
              color={theme.colors.primary}
              hoverColor={theme.colors.primaryDark}
              pad={2}
              width="100%"
              height={8}
              style={{position: 'absolute', zIndex: 1}}
            >
              <Text size="mlarge" font={theme.font.headings} lineHeight="msmall">
                {translate('Averages by markets')}
              </Text>
              <Text size="small" font={theme.font.secondaryFont} uppercase>
                {translate('Calculated average for selected period')}
              </Text>
            </Box>
            <MarketMap />
          </Box>
        )
      }
    >
      <InnerScroll noRightPad>
        <PeriodSelect
          customYears={generateYears(getCurrentYear(), 2).reverse()}
          setValue={setAggrInterval}
          setQuickLinkValue={setRawInterval}
          hideCustom
          hideQuarter
          withQuickLinks
        />
        <Divider sx={dividerStyles} />
        {!isEacTab && (
          <CountriesFilter
            countries={isGreenTarifTab ? greenTarifCountries : spotCountries}
            setCountries={isGreenTarifTab ? setGreenTarifCountries : setSpotCountries}
            disabledCountries={isGreenTarifTab ? GREEN_TARIFF_DISABLED_COUNTRIES : []}
          />
        )}
        <Divider sx={dividerStyles} />

        <Tabs name={MARKET_TAB_NAME}>
          {/* <Tab label={translate('EAC price')} slug="">
            <EacPrice />
          </Tab> */}
          <Tab label={translate('Spot market price')} slug="">
            <SpotPrice />
          </Tab>
          <Tab label={translate('Green tariff')} slug={MarketTab.GREEN_TARIFF}>
            <GreenTarif />
          </Tab>
          {/* <Tab label={translate('CO2 price of RE')} slug={MarketTab.CO2_PRICE_RE}>
            <div>co2</div>
          </Tab>
          <Tab label={translate('CO2 price/ton EUA')} slug={MarketTab.CO2_PRICE_EUA}>
            <div>co2eu</div>
          </Tab> */}
        </Tabs>
      </InnerScroll>
    </Page>
  )
}

export default MarketIntelligence
