import React from 'react'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {Tooltip} from '@mui/material'
import {rootServiceAtom} from 'atoms/general'
import {certificateLocationAtom, selectProductAtom, uploadGenerationAtom} from 'atoms/marketListings'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Button from 'components/Button'
import {FeatureFlags} from 'components/FeatureFlagProvider'
import FormInput from 'components/Form/FormInput'
import Label from 'components/Label'
import Text from 'components/Text'
import ValueWithUnit from 'components/ValueWithUnit'
import {IGenerateTestCertsPayload, MarketListingKind} from 'domain/IMarketListing'
import {SiteProductionTechnology, TimeseriesKind} from 'domain/ISite'
import {getGeneralNumCertificates} from 'helpers/certificates'
import useAlerts from 'hooks/useAlerts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useFormContext from 'hooks/useFormContext'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue} from 'jotai'
import {useEffect, useState} from 'react'
import theme from 'theme'

interface IProps {
  uploadType: TimeseriesKind
  newProduct: boolean
}

export const GeneralCertificate = ({uploadType, newProduct}: IProps) => {
  const [certificateResult, setCertificateResult] = useState<{
    volume: bigint
    error: string
  }>({volume: null, error: ''})
  const [disableCertEdit, setDisableCertEdit] = useState(false)
  const {translate} = useLocalization()
  const {getValues, setValue} = useFormContext()
  const {listing} = getValues({nest: true})
  const {getFeatureFlag} = useFeatureFlags()
  const {addError} = useAlerts()
  const allowGenerateCert = getFeatureFlag(FeatureFlags.ENABLE_CERT_GENERATION)
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const uploadGeneration = useAtomValue(uploadGenerationAtom)
  const selectedProduct = useAtomValue(selectProductAtom)
  const certificateLocation = useAtomValue(certificateLocationAtom)

  const {generation, interval} = uploadGeneration || {}
  const {profileAttachment, builder} = generation || {}
  const {technology, id: siteId} = selectedProduct?.site || {}
  const {id: productId, name: productName} = selectedProduct?.product || {}

  const {firstCertificate: startCert, lastCertificate: endCert} = listing || {}
  const hasTimeseries = uploadType === TimeseriesKind.BUILDER || profileAttachment?.id
  const hasProduct = !newProduct && productId
  const hasSite = newProduct && (technology || siteId)

  useEffect(() => {
    if (startCert || endCert) {
      const {value: certVolume, error: certVolumeError} = getGeneralNumCertificates(startCert, endCert)
      setCertificateResult({volume: certVolume, error: certVolumeError})
    }
  }, [startCert, endCert])

  const disableCertGenButton = !allowGenerateCert || !hasTimeseries || !(hasProduct || hasSite)

  const handleGenerateCertificates = async () => {
    try {
      const sitePayload = siteId
        ? {kind: MarketListingKind.EXISTING as const, payload: {id: +siteId}}
        : {
            kind: MarketListingKind.NEW as const,
            payload: {technology: technology as SiteProductionTechnology, placeId: certificateLocation.placeId},
          }

      const payload: IGenerateTestCertsPayload = {
        product: newProduct
          ? {
              kind: MarketListingKind.NEW as const,
              payload: {
                site: sitePayload,
              },
            }
          : {
              kind: MarketListingKind.EXISTING as const,
              payload: {
                id: +productId,
              },
            },
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder?.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? profileAttachment?.id : undefined,
          },
        },
      }
      const certs = await rootService.v2ContentService.generateMarketListingTestCertificates(party.id, payload)

      setValue('listing.firstCertificate', certs.start)
      setValue('listing.lastCertificate', certs.end)
      const {value: certVolume, error: certVolumeError} = getGeneralNumCertificates(certs.start, certs.end)
      setCertificateResult({volume: certVolume, error: certVolumeError})
      setDisableCertEdit(true)
    } catch (ex) {
      addError('Failed to generate test certificates', ex?.correlationId, ex?.message)
    }
  }

  return (
    <Box>
      <FormInput
        name="listing.price"
        label={translate('Price')}
        type="number"
        defaultValue={''}
        placeholder={translate('Insert price as number with max two decimals')}
        data-cy="listing-price"
        required
      />

      {allowGenerateCert && (
        <Box justify="end">
          <Button
            style={{zIndex: 99}}
            small
            type="button"
            variant="primary"
            onClick={handleGenerateCertificates}
            data-cy="generate-certs"
            disabled={disableCertGenButton}
          >
            <Tooltip
              title={disableCertGenButton ? translate('Previous steps should be completed') : undefined}
              arrow
              placement="bottom"
            >
              <div>
                <Box direction="row" gap={1}>
                  <AutoAwesomeIcon />
                  <Text>{translate('Generate test certificates')}</Text>
                  <AutoAwesomeIcon />
                </Box>
              </div>
            </Tooltip>
          </Button>
        </Box>
      )}

      <FormInput
        type="number"
        name="listing.firstCertificate"
        label={translate('Start certificate number')}
        required
        maxLength={35}
        minLength={15}
        disabled={disableCertEdit}
      />
      <FormInput
        type="number"
        name="listing.lastCertificate"
        label={translate('End certificate number')}
        required
        maxLength={35}
        minLength={15}
        disabled={disableCertEdit}
      />
      {certificateResult.error && (
        <Text size="mlarge" color={theme.colors.error}>
          {certificateResult.error}
        </Text>
      )}

      <Box margin={{vertical: 2}}>
        <Label text={translate('Certificate volume')}>
          <ValueWithUnit
            size="large"
            value={certificateResult.volume ? certificateResult.volume.toString() : '-'}
            unit={certificateResult.volume ? 'EACs' : ''}
          />
        </Label>
      </Box>
    </Box>
  )
}
