import useTheme from 'hooks/useTheme'
import React from 'react'
import {IBuyerContract} from 'domain/IContract'
import {generateContractTitleValue} from 'helpers/contract'
import {DateFormat, formatDate} from 'helpers/date'
import {formatEnergy} from 'helpers/format'
import {getCurrencySymbolValueSlashEnergyUnit} from 'helpers/price'
import useLocalization from 'hooks/useLocalization'
import Grid from 'components/Grid'
import ListItem from 'components/ListItem'
import coverImage from 'assets/images/onboarding-hero-img.webp'

interface IProps extends React.PropsWithChildren {
  contracts: IBuyerContract[]
}

const ContractList: React.FC<IProps> = ({contracts}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <Grid columns={3} gap={3} data-cy="contract-list-items">
      {contracts?.map(contract => (
        <ListItem
          to={`/consumer/portfolio/current-contracts/${contract.id}`}
          key={contract.id}
          title={translate('Contract %s', generateContractTitleValue(contract.contract))}
          coverImage={coverImage}
          description={formatDate(contract.contract.signingDate)}
          features={[
            {
              name: translate('Price per certificate'),
              value: getCurrencySymbolValueSlashEnergyUnit(contract.certPrice, theme, contract.priceUnit, true),
            },
            {
              name: translate('Certificate quantity'),
              value: `${formatEnergy(contract.contract.volumeMwh)} ${translate('EACs')}`,
            },
            {
              name: translate('Contract term'),
              value: (
                <>
                  {formatDate(contract.contract.validFrom, DateFormat.DAY_MONTH_YEAR)}
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  {formatDate(contract.contract.validTo, DateFormat.DAY_MONTH_YEAR)}
                </>
              ),
            },
          ]}
        />
      ))}
    </Grid>
  )
}

export default ContractList
