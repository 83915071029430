import React, {useMemo, useState} from 'react'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import FormSelect from 'components/Form/FormSelect'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import DownloadExampleLink from './DownloadExampleLink'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import {FormControlLabel, RadioGroup} from '@mui/material'
import Radio from 'components/Radio'
import {getChangeEventValue} from 'helpers/misc'
import useFormContext from 'hooks/useFormContext'
import {generateYears, getLastFullMonth, getPrevMonthYear, getMonths, getPrevMonth} from 'helpers/date'

interface IProps extends React.PropsWithChildren {
  uploadType: TimeseriesKind
  setUploadType: (value: TimeseriesKind) => void
}

const BuyerConsumption: React.FC<IProps> = ({uploadType, setUploadType}) => {
  const {translate} = useLocalization()
  const [interval, setInterval] = useState({
    month: getPrevMonth(),
    year: getPrevMonthYear(),
  })

  const months = getMonths()
  const availableMonths = useMemo(() => {
    const lastFullMonth = getLastFullMonth()
    const lastFullMonthYear = getPrevMonthYear()

    if (interval.year === lastFullMonthYear) {
      return months.slice(0, months.indexOf(lastFullMonth) + 1)
    }
    return months
  }, [interval?.year])

  const years = useMemo(() => {
    const lastFullMonthYear = getPrevMonthYear()
    return generateYears(lastFullMonthYear, 3)
  }, [])

  return (
    <Box round border pad={2}>
      <Label text={translate('Interval')}>
        <Box direction="row" gap={1}>
          <FormSelect
            required
            name="interval.month"
            margin={0}
            defaultValue={getPrevMonth()}
            options={availableMonths.map((month, index) => ({value: month, label: month}))}
            placeholder="Month"
            onChange={value => setInterval({...interval, month: value})}
          />
          <FormSelect
            required
            name="interval.year"
            margin={0}
            defaultValue={getPrevMonthYear()}
            options={years.map(year => ({value: year, label: `${year}`}))}
            placeholder="Year"
            onChange={value => setInterval({...interval, year: +value})}
          />
        </Box>
      </Label>

      <RadioGroup sx={{m: 2, ml: 1}} row value={uploadType} onChange={e => setUploadType(getChangeEventValue(e))}>
        <FormControlLabel value={TimeseriesKind.BUILDER} control={<Radio />} label={translate('Build')} />
        <FormControlLabel value={TimeseriesKind.FILE_UPLOAD} control={<Radio />} label={translate('Upload')} />
      </RadioGroup>
      {uploadType === TimeseriesKind.BUILDER && <FormProfileBuilder name="builder" />}
      {uploadType === TimeseriesKind.FILE_UPLOAD && (
        <>
          <FormAttachmentUpload
            coloredLink
            label={translate('Upload consumption profile')}
            name="file"
            removable
            required
          />
          <Box margin={{vertical: 2}}>
            <DownloadExampleLink interval={interval} siteType={SiteType.CONSUMPTION} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default BuyerConsumption
