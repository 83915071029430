import {SiteProductionTechnology} from 'domain/ISite'
import React from 'react'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {getHumanReadableTechnologyName} from 'helpers/site'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import {ISellerSignedContract} from 'domain/IContract'
import {getContractsVolumeMwhByTechnology} from 'helpers/contract'
import EnergySummaryItem from 'components/Platform/Portfolio/Summary/EnergySummaryItem'
import {Skeleton} from '@mui/material'
import Box from 'components/Box'
import TechnologyIcon from 'components/Platform/TechnologyIcon'

interface IProps extends React.PropsWithChildren {
  contracts: ISellerSignedContract[]
  loading?: boolean
}

const ContractsSummaryByTechnology: React.FC<IProps> = ({contracts, loading}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const volumeMwhByTechnology = getContractsVolumeMwhByTechnology(contracts)
  const total: number = contracts?.reduce((acc, current) => acc + current.volumeMwh, 0)
  const unitSuffix = `/${translate('year')}`

  if (loading) {
    return (
      <Box direction="row" margin={{top: 3}} gap={4}>
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
      </Box>
    )
  }

  return (
    <ResourceSummary noPad>
      <EnergySummaryItem
        title={translate('Current page total volume')}
        value={total}
        color={theme.colors.light4}
        max={total}
        unit={`EACs${unitSuffix}`}
      />
      {Object.keys(volumeMwhByTechnology).map((technology: SiteProductionTechnology) => (
        <EnergySummaryItem
          key={technology}
          icon={<TechnologyIcon technology={technology} />}
          title={translate('Current page %s volume', getHumanReadableTechnologyName(technology, translate))}
          value={volumeMwhByTechnology[technology]}
          max={total}
          color={theme.colors.technology[technology]}
          unit={`EACs${unitSuffix}`}
        />
      ))}
    </ResourceSummary>
  )
}

export default ContractsSummaryByTechnology
