import React, {useEffect, useState} from 'react'
import Loader from 'components/Loader'
import useLocalization from 'hooks/useLocalization'
import useAlerts from 'hooks/useAlerts'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'

const ContractHtmlPreview: React.FC<{id: any; buyer?: boolean; docRef: React.RefObject<HTMLDivElement>}> = ({
  id,
  buyer,
  docRef,
}) => {
  const {translate} = useLocalization()
  const {addError} = useAlerts()
  const [html, setHtml] = useState(null)
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)

  useEffect(() => {
    rootService.v2ContentService[buyer ? 'getBuyerContractHtml' : 'getSellerContractHtml'](party?.id, id)
      .then(html => setHtml(html))
      .catch(error => {
        console.error(error)
        addError(translate('Failed to retrieve contract preview'), error?.correlationId, error?.message)
        setHtml('')
      })
    // eslint-disable-next-line
  }, [id])

  if (html === null) {
    return <Loader margin={3} />
  }

  return <div ref={docRef} dangerouslySetInnerHTML={{__html: html}} />
}

export default ContractHtmlPreview
