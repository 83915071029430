import {PrimitiveAtom, atom} from 'jotai'
import {atomWithCleanup, loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'
import {ISelectProductFormData, IUploadGenerationFormData, ICertificateFormData} from 'domain/IMarketListing'
import ILocation, {Country} from 'domain/ILocation'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const productIdAtom = atomWithCleanup<string>(null)

export const selectProductAtom = atom(null) as PrimitiveAtom<ISelectProductFormData>
export const uploadGenerationAtom = atom(null) as PrimitiveAtom<IUploadGenerationFormData>
export const certificateLocationAtom = atom(null) as PrimitiveAtom<{
  placeId: string
  addressCountry: Country
}>

export const marketListingsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    const data = await services.v2ContentService.getMarketListings(party?.id, page, pageSize)

    return data
  }),
)

export const basicProductsAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getBasicProducts(party?.id)

    return data
  }),
)

export const basicSitesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getBasicSites(party?.id)

    return data
  }),
)

export const marketListingsByProductAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)
    const productId = get(productIdAtom)

    if (!productId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingsByProduct(party?.id, productId, page, pageSize)

    return data
  }),
  {nullIsLoading: true},
)

export const productAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const productId = get(productIdAtom)

    if (!productId) {
      return null
    }

    const data = await services.v2ContentService.getProductManagedByParty(party?.id, productId)

    return data
  }),
  {nullIsLoading: true},
)

export const marketListingIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const marketListingAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const marketListingId = get(marketListingIdAtom)

    if (!marketListingId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingById(party?.id, marketListingId)

    return data
  }),
  {nullIsLoading: true},
)

export const marketListingReportAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const marketListingId = get(marketListingIdAtom)

    if (!marketListingId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingCertReport(party?.id, marketListingId)

    return data
  }),
  {nullIsLoading: true},
)
