import React from 'react'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import Box from 'components/Box'
import SiteMap from 'components/Platform/Map/SiteMap'
import InnerScroll from 'components/InnerScroll'
import SalesContractList from './SalesContractList'
import {useAtom, useAtomValue} from 'jotai'
import ContractsSummaryByTechnology from './ContractsSummaryByTechnology'
import {pageAtom, pageSizeAtom, sellerContractsAtom} from 'atoms/contracts'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import {ContractState} from 'domain/IContract'
import {uniqBy} from 'lodash-es'
import ErrorInfo from 'components/ErrorInfo'

const RESULT_PER_PAGE_OPTIONS = [25, 50]

const SalesContractListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const {data: paginatedContracts, loading, error} = useAtomValue(sellerContractsAtom)
  const {data: contracts} = paginatedContracts || {}
  const filteredContracts = contracts?.filter(c => c.state === ContractState.SIGNED)
  const sites = uniqBy(
    filteredContracts?.map(c => c.site),
    'id',
  )

  return (
    <Page
      title={translate('Signed contracts')}
      error={error && <ErrorInfo error={error} title={translate('Failed to load contracts')} />}
      description={translate('Overview of signed contracts')}
      aside={
        <SiteMap
          title={translate('Production sites')}
          description={translate('Overview of contracted production sites')}
          sites={sites || []}
        />
      }
    >
      <InnerScroll noRightPad>
        <ContractsSummaryByTechnology contracts={filteredContracts} loading={loading} />
        <Box margin={{vertical: 4}} />
        <SalesContractList contracts={filteredContracts} loading={loading} />
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={paginatedContracts?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default SalesContractListPage
