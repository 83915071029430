import {rootServiceAtom} from 'atoms/general'
import {managedUsersAtom} from 'atoms/managedUsers'
import Form from 'components/Form'
import FormEmail from 'components/Form/FormEmail'
import FormInput from 'components/Form/FormInput'
import FormName from 'components/Form/FormName'
import FormSelect from 'components/Form/FormSelect'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Heading from 'components/Heading'
import {IManagedUser} from 'domain/IManagedUser'
import {IPartyMemberCreateRequest} from 'domain/IPartyMemberCreateRequest'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {isPartyAdmin, stringifyMemberRole} from 'helpers/partyMember'
import useAlerts from 'hooks/useAlerts'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import {Redirect, useHistory} from 'react-router-dom'

interface IProps extends React.PropsWithChildren {
  urlPrefix: string
}

interface AddPartyMemberFormData extends Omit<IManagedUser, 'id'> {
  memberRole: PartyMemberRole
}

const AddPartyMemberForm: React.FC<IProps> = ({urlPrefix}) => {
  const {translate} = useLocalization()
  const {partyMembership, user, party} = useProfile()
  const {addSuccess} = useAlerts()
  const history = useHistory()
  const refetchManagedUsers = useSetAtom(managedUsersAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService

  const handleSubmit = async (formData: AddPartyMemberFormData) => {
    const defaultPartyMemberRole = PartyMemberRole.EMPLOYEE
    const partyMemberRoles = [formData.memberRole]

    if (!partyMemberRoles.includes(defaultPartyMemberRole)) {
      partyMemberRoles.push(defaultPartyMemberRole)
    }

    const partyMember: IPartyMemberCreateRequest = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailAddress: formData.email,
      partyMemberRole: formData.memberRole,
    }

    const createdv2 = await v2ContentService.createPartyMember(party.id, partyMember)

    refetchManagedUsers()

    history.push(`${urlPrefix}/company/members/edit/${createdv2.id}`)
    addSuccess(translate('User created'))
  }

  if (!isPartyAdmin(partyMembership)) {
    return <Redirect to={'/'} />
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <Heading>{translate('Personal information')}</Heading>
      <FormName label={translate('First name')} name="firstName" placeholder={user.firstName} required />
      <FormName label={translate('Surname')} name="lastName" placeholder={user.lastName} required />
      <FormEmail placeholder={user.email} label={translate('Email address')} name="email" required />
      <FormSelect
        label={translate('Role')}
        name="memberRole"
        defaultValue={PartyMemberRole.EMPLOYEE}
        options={Object.values(PartyMemberRole).map(role => ({
          value: role,
          label: stringifyMemberRole(role, translate),
        }))}
      ></FormSelect>

      <FormSubmitButton margin={{top: 1}}>{translate('Save')}</FormSubmitButton>
    </Form>
  )
}

export default AddPartyMemberForm
