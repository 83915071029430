import React from 'react'
import ProductListPage from 'components/Platform/Portfolio/Product/ProductListPage'
import ResourceGateway from 'components/ResourceGateway'
import ProductEditPage from 'components/Platform/Portfolio/Product/ProductEditPage'

const Products = () => {
  return (
    <ResourceGateway
      single={routeProps => <ProductEditPage productId={routeProps.match.params.id} />}
      list={() => <ProductListPage urlPrefix="/broker/portfolio/products" />}
    />
  )
}

export default Products
