import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'
import {autoCeilNumber, autoFloorNumber} from '../../../../helpers/misc'
import useTheme from 'hooks/useTheme'
import ISitesDashboard from 'domain/ISitesDashboard'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  timeseries: ISitesDashboard
  hideTotals?: boolean
  selectedSites?: number[]
  setSelectedSite?: (sites: number) => void
}

const DashboardChart: React.FC<IProps> = ({
  timeseries,
  hideTotals = false,
  selectedSites = [],
  setSelectedSite = () => {},
}) => {
  const chartColors = useNewChartColors()
  const theme = useTheme()

  if (!timeseries) {
    return null
  }
  const {summary, sites} = timeseries
  const labels = summary.sitesTotal.map(({name}) => name) || []
  const selectedEmpty = selectedSites.length === 0

  const lines: ILine[] = []

  if (!hideTotals) {
    // Will be added later, when we have filtering from BE
    // lines.push({
    //   key: 'sitesTotal',
    //   label: 'Sites total',
    //   color: theme.colors.common.black,
    //   data: summary.sitesTotal.map(({value}) => value),
    //   type: ChartType.LINE,
    //   stackType: StackType.DEFAULT,
    //   fill: false,
    //   showPoint: true,
    // })

    lines.push({
      key: 'partyTotal',
      label: 'Party total',
      color: theme.colors.blue7,
      data: summary.partyTotal.map(({value}) => value),
      type: ChartType.LINE,
      stackType: StackType.SECONDARY,
      fill: false,
      showPoint: true,
    })
  }

  lines.push(
    ...sites.map((item, index) => ({
      key: `${item.siteId}`,
      label: item.siteName,
      color: chartColors.getNext(),
      data: selectedEmpty || selectedSites.includes(item.siteId) ? item.timeseries.map(({value}) => value) : [],
      type: ChartType.LINE,
      onClick: () => setSelectedSite(item.siteId),
      isSelected: selectedSites.includes(item.siteId),
      stackType: StackType.STACKED,
      fill: true,
    })),
  )

  return (
    <Chart
      toolbar={{vertical: false, timescale: true}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default DashboardChart
