import dayjs from 'dayjs'
import IParty from 'domain/IParty'
import {
  getCurrentMonth,
  getCurrentYear,
  getMonthNumber,
  getPrevMonth,
  getPrevMonthYear,
  lastDayOfMonth,
} from 'helpers/date'
import {atom, PrimitiveAtom} from 'jotai'
import {intervalAtom, rootServiceAtom} from './general'
import {partyAtom} from './party'
import {atomWithCleanup, atomWithCompare, loadable} from './utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)
export const passedPartyAtom = atom<IParty>(null) as PrimitiveAtom<IParty>
const refetchAtom = atom(0)

export const refetchSitesDataAtom = atom(null, (get, set) => {
  set(refetchAtom, get(refetchAtom) + 1)
})

export const defaultDateFilter = {
  startMonth: getPrevMonth(),
  startYear: getPrevMonthYear(),
  endMonth: getPrevMonth(),
  endYear: getPrevMonthYear(),
}

export const dateFilterAtom = atomWithCompare(defaultDateFilter)

export const sitesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)
    const passedParty = get(passedPartyAtom)
    const _ = get(refetchAtom)

    const data = await services.v2ContentService.getPortfolioSites(passedParty?.id || party?.id, page, pageSize)

    return data
  }),
)

export const dashboardAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const interval = get(intervalAtom)
    const passedParty = get(passedPartyAtom)
    const _ = get(refetchAtom)
    const dateFilter = get(dateFilterAtom)

    const endMonthNr = getMonthNumber(dateFilter.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth()
    const endDay =
      isCurrentMonth && dateFilter.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, dateFilter.endYear)
    const startDate = `${dateFilter.startYear}-${getMonthNumber(dateFilter.startMonth) + 1}-1T00:00:00`
    const endDate = `${dateFilter.endYear}-${endMonthNr + 1}-${endDay}T23:00:00`

    const data = await services.v2ContentService.getPortfolioSitesDashboard(
      passedParty?.id || party?.id,
      interval,
      startDate,
      endDate,
    )

    return data
  }),
)

export const buyerSiteIdAtom = atomWithCleanup<number>(null) as PrimitiveAtom<number>

export const buyerSiteTimeseriesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const siteId = get(buyerSiteIdAtom)
    const _ = get(refetchAtom)
    const dateFilter = get(dateFilterAtom)
    const interval = get(intervalAtom)

    if (!siteId) {
      return null
    }

    const endMonthNr = getMonthNumber(dateFilter.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth() && dateFilter.endYear === getCurrentYear()
    const endDay =
      isCurrentMonth && dateFilter.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, dateFilter.endYear)
    const startDate = `${dateFilter.startYear}-${getMonthNumber(dateFilter.startMonth) + 1}-1T00:00:00`
    const endDate = `${dateFilter.endYear}-${endMonthNr + 1}-${endDay}T23:00:00`

    const data = await services.v2ContentService.getSiteTimeseries(party?.id, siteId, startDate, endDate, interval)

    return data
  }),
  {nullIsLoading: true},
)

export const sellerSiteTimeseriesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const siteId = get(sellerSiteIdAtom)
    const _ = get(refetchAtom)
    const dateFilter = get(dateFilterAtom)
    const interval = get(intervalAtom)

    if (!siteId) {
      return null
    }

    const endMonthNr = getMonthNumber(dateFilter.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth() && dateFilter.endYear === getCurrentYear()
    const endDay =
      isCurrentMonth && dateFilter.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, dateFilter.endYear)
    const startDate = `${dateFilter.startYear}-${getMonthNumber(dateFilter.startMonth) + 1}-1T00:00:00`
    const endDate = `${dateFilter.endYear}-${endMonthNr + 1}-${endDay}T23:00:00`

    const data = await services.v2ContentService.getSiteTimeseries(party?.id, siteId, startDate, endDate, interval)

    return data
  }),
  {nullIsLoading: true},
)

export const buyerSiteAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const siteId = get(buyerSiteIdAtom)
    const _ = get(refetchAtom)

    if (!siteId) {
      return null
    }

    const data = await services.v2ContentService.getBuyerSite(party?.id, siteId)

    return data
  }),
  {nullIsLoading: true},
)
export const sellerSiteIdAtom = atomWithCleanup<number>(null) as PrimitiveAtom<number>

export const sellerSiteAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const siteId = get(sellerSiteIdAtom)
    const _ = get(refetchAtom)

    if (!siteId) {
      return null
    }

    const data = await services.v2ContentService.getSellerSite(party?.id, siteId)

    return data
  }),
  {nullIsLoading: true},
)
