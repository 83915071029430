import React, {useState} from 'react'
import Text from 'components/Text'
import Box from 'components/Box'
import Table from 'components/Table'
import {DateFormat, formatDate} from 'helpers/date'
import Checkbox from 'components/Checkbox'
import useLocalization from 'hooks/useLocalization'
import InnerScroll from 'components/InnerScroll'
import {CertSchemeType, ICertificateOfferMatchedCert} from 'domain/ICertificateOffer'
import {TableCertValue} from '../MarketplaceListing/MatchedCertsTable'

const MatchedCertsTable: React.FC<{data: ICertificateOfferMatchedCert[]}> = ({data}) => {
  const {translate} = useLocalization()
  const [showAccr, setShowAccr] = useState(true)
  const [showDates, setShowDates] = useState(true)
  const [showPrefix, setShowPrefix] = useState(true)
  const isEuCert = data?.some(obj => obj?.cert?.certScheme === CertSchemeType.EECS)

  return (
    <InnerScroll>
      <Box gap={3} direction="row" pad={{horizontal: 1}}>
        {isEuCert ? (
          <Checkbox
            label={translate('Show prefix numbers')}
            checked={showPrefix}
            onChange={() => setShowPrefix(!showPrefix)}
          />
        ) : (
          <>
            <Checkbox
              label={translate('Show accredation numbers')}
              checked={showAccr}
              onChange={() => setShowAccr(!showAccr)}
            />
            <Checkbox
              label={translate('Show dates and suffixes')}
              checked={showDates}
              onChange={() => setShowDates(!showDates)}
            />
          </>
        )}
      </Box>

      <Table
        tableName={translate('Certificates breakdown')}
        data={data}
        sortable={false}
        columns={[
          {
            title: translate('Date'),
            accessor: report => report.timestamp,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {formatDate(date, DateFormat.MONTH_DAY_YEAR)}
              </Text>
            ),
          },
          {
            title: translate('Certificates'),
            accessor: report => report.cert,
            render: value => (
              <TableCertValue
                value={value}
                showAccr={showAccr}
                showDates={showDates}
                showPrefix={showPrefix}
                isEuCert={isEuCert}
              />
            ),
          },
        ]}
      />
    </InnerScroll>
  )
}

export default MatchedCertsTable
