import React, {useState} from 'react'
import IContract, {ContractState} from 'domain/IContract'
import Box from 'components/Box'
import {DownloadLink1} from 'components/DownloadLink'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import Loader from 'components/Loader'
import IPurchaseContract from 'domain/IPurchaseContract'
import {ICertOfferContract} from 'domain/ICertificateOffer'
import useAlerts from 'hooks/useAlerts'
import useDocumentDownload from 'hooks/useDocumentDownload'

const DownloadContract: React.FC<{
  contract: IContract | IPurchaseContract | ICertOfferContract
  docRef: React.RefObject<HTMLDivElement>
}> = ({contract, docRef}) => {
  const {translate} = useLocalization()
  const {addError} = useAlerts()
  const [loading, setLoading] = useState<boolean>(false)
  const isSigned = contract?.state === ContractState.SIGNED
  const {downloadDocument} = useDocumentDownload({
    filename: `contract-${contract?.id}${isSigned ? '-signed' : '-draft'}.pdf`,
  })
  const title = isSigned ? translate('Contract') : translate('Contract draft')

  return (
    <>
      {loading && <Loader margin={{left: 1}} />}
      <DownloadLink1
        title={title}
        name={'REGO Contract'}
        onClick={async () => {
          setLoading(true)
          try {
            await downloadDocument(docRef)
            setLoading(false)
          } catch (e) {
            console.error(e)
            addError(translate('%s download failed', title), e?.correlationId, e?.message)
            setLoading(false)
          }
        }}
      />
    </>
  )
}

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract | ICertOfferContract
  docRef: React.RefObject<HTMLDivElement>
}

const ContractDownloadLinks: React.FC<IProps> = ({contract, docRef}) => {
  const theme = useTheme()

  return (
    <Box color={theme.colors.light3} round pad={2} margin={{vertical: 3}} direction="row" gap={5}>
      <DownloadContract contract={contract} docRef={docRef} />
    </Box>
  )
}

export default ContractDownloadLinks
