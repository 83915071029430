import {signIn, signOut, resetPassword, confirmResetPassword, fetchAuthSession, updatePassword} from '@aws-amplify/auth'
import {Amplify} from 'aws-amplify'
import ValidationError from '../exceptions/ValidationError'
import IAuthenticationService from './IAuthenticationService'

interface CognitoConfig {
  region: string
  userPoolId: string
  userPoolWebClientId: string
}

export default class CognitoAuthenticationService implements IAuthenticationService {
  public constructor({region, userPoolId, userPoolWebClientId}: CognitoConfig, endpoint: string) {
    const {fetch: originalFetch} = window

    // To append custom headers to Cognito requests(Amplify v6 does not support this yet)
    window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
      const targetHeader = init?.headers?.['x-amz-target'] // headers are in lower case here
      if (targetHeader?.startsWith('AWSCognitoIdentityProviderService')) {
        init.headers = {
          ...init.headers,
          'X-UI-TRACE-ID': localStorage?.getItem('sessionId'),
        }
      }

      return await originalFetch(input, init)
    }

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId,
          userPoolClientId: userPoolWebClientId,
          loginWith: {
            email: true,
            username: true,
          },
          userPoolEndpoint: endpoint,
        },
      },
      API: {
        REST: {
          renewable: {
            endpoint: endpoint,
            region: region,
          },
        },
      },
    })
  }

  public async signIn(email: string, password: string): Promise<void> {
    try {
      await signIn({username: email, password})
    } catch (error) {
      console.error('Sign in error:', error)
      throw error
    }
  }

  public async getToken(): Promise<string> {
    try {
      const {tokens} = await fetchAuthSession()
      return tokens?.idToken?.toString()
    } catch (err) {
      return undefined
    }
  }

  public async refreshToken(): Promise<string> {
    try {
      const {tokens} = await fetchAuthSession({forceRefresh: true})
      return tokens?.idToken?.toString()
    } catch (err) {
      return undefined
    }
  }

  public async signOut(): Promise<void> {
    await signOut()
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await updatePassword({oldPassword, newPassword})
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        throw new ValidationError('Incorrect current password', [
          {
            key: 'oldPassword',
            message: 'Incorrect password',
          },
        ])
      }

      throw error
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    await resetPassword({username: email})
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string): Promise<void> {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    })
  }
}
