import React from 'react'
import Box from 'components/Box'
import Label from 'components/Label'
import Text from 'components/Text'
import {Item} from 'components/Platform/Portfolio/ContractItem'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import ValueWithUnit from 'components/ValueWithUnit'
import {getCurrencySymbol} from 'helpers/price'
import useLocalization from 'hooks/useLocalization'
import useServices from 'hooks/useServices'
import styled from 'styled-components'
import {ICertificateProduct} from 'domain/IProduct'
import {CurrencyCode} from 'domain/IPrice'
import useTheme from 'hooks/useTheme'

interface ISelectedProductProps {
  product: ICertificateProduct
  currency: CurrencyCode
  description?: string
}

const MinWithSpan = styled.span<{width: number}>`
  width: ${props => props.width}px;
`

const LastBox = styled(Box)`
  margin-left: auto;
  min-width: 130px;
  margin-right: ${props => props.theme.tileSize * 4}px;
`

const OfferProduct: React.FC<ISelectedProductProps> = ({product, currency, description}) => {
  const {contentService} = useServices()
  const theme = useTheme()
  const {translate} = useLocalization()
  const productDescription = product?.description || description

  if (!product) return null

  return (
    <Item
      name={product?.name}
      image={contentService.getAttachmentURL(product?.image)}
      color={theme.colors.technology[product.technology]}
      height={productDescription ? 20 : 16}
    >
      <Box direction="column" gap={2} width="100%">
        {productDescription && (
          <Label text={translate('Description')}>
            {productDescription
              .substring(0, 100)
              .split('\n')
              .map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                )
              })}
          </Label>
        )}

        <Box gap={3} width={'100%'}>
          <MinWithSpan width={125}>
            {product?.technology && (
              <Label text={translate('Technology')}>
                <TechnologyIconAndName technology={product.technology} semibold />
              </Label>
            )}
          </MinWithSpan>

          <MinWithSpan width={150}>
            <Label text={'Volume'}>
              <ValueWithUnit size="mlarge" value={product.quantity} unit={'CERTIFICATES'} dataCy="product-volume" />
            </Label>
          </MinWithSpan>

          <LastBox>
            <Label text={translate('Price')}>
              <ValueWithUnit
                size="mlarge"
                unit="/ CERTIFICATE"
                value={parseFloat(product.certificatePrice).toFixed(2)}
                currency={getCurrencySymbol(currency)}
              />
            </Label>
          </LastBox>
        </Box>
      </Box>
    </Item>
  )
}

export default OfferProduct
