import React, {useEffect} from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import {useAtomValue, useSetAtom} from 'jotai'
import {marketListingAtom, marketListingIdAtom, marketListingsAtom} from 'atoms/marketListings'
import Form from 'components/Form'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate} from 'helpers/date'
import Page from 'components/Page'
import Button from 'components/Button'
import {IMarketListing} from 'domain/IMarketListing'
import {useHistory} from 'react-router'
import {getCurrencySymbol} from 'helpers/price'
import useAlerts from 'hooks/useAlerts'
import InnerScroll from 'components/InnerScroll'

interface IProps extends React.PropsWithChildren {
  id: number
}

const EditMarketListingPage: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {addError, addSuccess} = useAlerts()
  const rootService = useAtomValue(rootServiceAtom)
  const setMarketListingId = useSetAtom(marketListingIdAtom)
  const refetchListings = useSetAtom(marketListingsAtom)
  const party = useAtomValue(partyAtom)
  const {data: listing} = useAtomValue(marketListingAtom)

  useEffect(() => {
    if (id) {
      setMarketListingId(id)
    }

    return () => {
      setMarketListingId(null)
    }
  }, [id])

  const handleSubmit = async (data, {setError}) => {
    const {certPrice} = data as IMarketListing

    try {
      await rootService.v2ContentService.updateMarketListing(party.id, id, {certPrice: +certPrice})
      addSuccess(translate('Marketplace listing updated'))
      refetchListings()
      history.push('/broker/sell/marketplace-listings')
    } catch (error) {
      addError(error?.message, error?.correlationId)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      fillHeight
      submissionFeedback={null}
      defaultValues={{...listing, startDate: formatDate(listing?.startDate, DateFormat.MONTH_YEAR)}}
    >
      <Page
        title={translate('Marketplace listing')}
        noDivider
        corner={
          <Button data-cy="save-button" type="submit">
            {translate('Save')}
          </Button>
        }
      >
        <InnerScroll noRightPad>
          <Box border round pad={2}>
            <FormInput defaultValue="" disabled name="productName" label={translate('Product name')} />
            <FormInput
              defaultValue=""
              type="number"
              name="certPrice"
              label={translate('Price per EAC')}
              prefix={<Box>{getCurrencySymbol(listing?.currency)}</Box>}
              required
              data-cy="cert-price"
            />
            <FormInput defaultValue="" disabled name="startDate" label={translate('Start date')} />
            <FormInput defaultValue="" disabled name="totalQuantity" label={translate('Total EACs')} />
            <FormInput defaultValue="" disabled name="soldQuantity" label={translate('Sold EACs')} />
            <FormInput defaultValue="" disabled name="reservedQuantity" label={translate('Reserved EACs')} />
            <FormInput defaultValue="" disabled name="availableQuantity" label={translate('Available EACs')} />
          </Box>
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default EditMarketListingPage
