import React from 'react'
import Table, {TableValue} from 'components/Table'
import {DateFormat, formatDate, getPeriod} from 'helpers/date'
import useLocalization from 'hooks/useLocalization'
import {getCurrencyCodeByPriceUnit} from 'helpers/price'
import {ISellerSignedContract} from 'domain/IContract'

interface IProps extends React.PropsWithChildren {
  contracts: ISellerSignedContract[]
  loading?: boolean
}

const SalesContractList: React.FC<IProps> = ({contracts, loading}) => {
  const {translate} = useLocalization()

  return (
    <Table<ISellerSignedContract>
      data={contracts}
      isLoading={loading}
      linkTo={contract => `/broker/portfolio/signed-agreements/${contract?.offer?.id}`}
      columns={[
        {
          title: translate('Contract id'),
          accessor: contract => `#${contract.id}`,
        },
        {
          title: translate('Name'),
          accessor: contract => contract.name,
        },
        {
          title: translate('Customer'),
          accessor: (contract: ISellerSignedContract) => contract?.customer?.name,
          // eslint-disable-next-line react/display-name
          render: (value: string) => <strong>{value}</strong>,
        },
        {
          title: translate('Signing date'),
          accessor: (contract: ISellerSignedContract) => formatDate(contract?.signingDate, DateFormat.MONTH_DAY_YEAR),
          render: (date: string) => <TableValue text={date} />,
        },
        {
          title: translate('Volume'),
          accessor: contract => contract.volumeMwh,
          render: (value: number) => <TableValue value={value} unit={'EAC'} />,
        },
        {
          title: translate('Start and end date'),
          accessor: contract => getPeriod(contract.validFrom, contract.validTo),
        },
        {
          title: translate('EAC price'),
          accessor: contract => contract,
          render: (contract: ISellerSignedContract) => (
            <TableValue
              value={contract?.offer?.certPrice}
              unit={'EAC'}
              currency={getCurrencyCodeByPriceUnit(contract?.offer?.priceUnit)}
            />
          ),
        },
        {
          title: translate('Total price'),
          accessor: contract => contract,
          render: (contract: ISellerSignedContract) => (
            <TableValue
              value={contract?.offer?.totalPrice}
              unit={'EAC'}
              currency={getCurrencyCodeByPriceUnit(contract?.offer?.priceUnit)}
            />
          ),
        },
      ]}
    />
  )
}

export default SalesContractList
