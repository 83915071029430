import {ContractState} from './IContract'
import IContractType from './IContractType'
import {OfferState} from './IOffer'
import IParty from './IParty'
import {ICertificateProduct} from './IProduct'
import IProposalDetails from './IProposalDetails'

export interface ICertOfferContract {
  id: number
  state: ContractState
  name: string
  contractType?: IContractType
}

export enum CertSchemeType {
  EECS = 'EECS',
  OFGEM = 'OFGEM',
}

export interface ICertificateOfferMatchedCert {
  timestamp: string
  cert: {
    start: string
    end: string
    certScheme: CertSchemeType
  }
}

export default interface ICertificateOffer extends IProposalDetails {
  id?: number
  name: string
  state: OfferState
  party?: IParty
  product: ICertificateProduct
  purchaseInterval: {
    year: number
    month: string
  }
  estimatedVolume: number
  contract: ICertOfferContract
  matchedCerts: ICertificateOfferMatchedCert[]
}
