import {rootServiceAtom} from 'atoms/general'
import {managedUserAtom, managedUserIdAtom, managedUsersAtom} from 'atoms/managedUsers'
import {partyAtom, partyMembersAtom, selectedPartyMemberAtom} from 'atoms/party'
import FullscreenLoader from 'components/FullscreenLoader'
import Page from 'components/Page'
import EditManagedPartyMemberForm, {IFormData} from 'components/Platform/Account/EditManagedPartyMember'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {isPartyAdmin} from 'helpers/partyMember'
import useAlerts from 'hooks/useAlerts'
import Error from 'components/Error'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'

interface IProps {
  partyMemberId: string
}

const EditPartyMember: React.FC<IProps> = ({partyMemberId}) => {
  const {translate} = useLocalization()
  const party = useAtomValue(partyAtom)
  const selectedPartyMember = useAtomValue(selectedPartyMemberAtom)
  const {addError, addSuccess} = useAlerts()
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService
  const setPartyMemberId = useSetAtom(managedUserIdAtom)
  const refetchManagedUsers = useSetAtom(managedUsersAtom)
  const refetchManagedUser = useSetAtom(managedUserAtom)
  const {data: managedUser, loading, error} = useAtomValue(managedUserAtom)

  useEffect(() => {
    setPartyMemberId(Number.parseInt(partyMemberId))
  }, [partyMemberId])

  const handleSubmit = async (formData: IFormData) => {
    const updatePartyMemberReq: IPartyMemberUpdateRequest = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailAddress: formData.email,
      partyMemberRole: formData.role,
    }

    try {
      await v2ContentService.updatePartyMember(party.id, managedUser.detMember.member.id, updatePartyMemberReq)
      addSuccess(translate('User saved') + '.')
      refetchManagedUser()
      refetchManagedUsers()
    } catch (error) {
      addError('Failed to update user.', error?.correlationId, error?.message)
    }
  }

  if (!isPartyAdmin(selectedPartyMember)) {
    return <Redirect to={'/'} />
  }

  return (
    <Page
      isLoading={loading}
      error={error && <Error title={translate('Failed to load user')} />}
      title={translate('Edit user profile')}
      description={translate('Edit user')}
      noDivider
    >
      <EditManagedPartyMemberForm partyMember={managedUser} handleSubmit={handleSubmit} />
    </Page>
  )
}

export default EditPartyMember
