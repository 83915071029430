import {Country} from 'domain/ILocation'
import {getAllowedCountries} from 'helpers/location'
import {atom} from 'jotai'
import {atomWithCompare, loadable} from './utils'
import {atomWithDefault, atomWithRefresh} from 'jotai/utils'
import {rootServiceAtom} from './general'
import {partyAtom} from './party'
import dayjs from 'dayjs'
import {Interval} from 'services/IContentService'
import {getCurrentMonth, getCurrentYear, getMonthNumber, lastDayOfMonth} from 'helpers/date'

export const GREEN_TARIFF_DISABLED_COUNTRIES = [Country.GBR]

export const spotCountriesAtom = atomWithDefault(get => {
  const party = get(partyAtom)

  if (!party) {
    return []
  }

  return [party.location?.addressCountry, ...getAllowedCountries()].filter(Boolean).slice(0, 5)
})

export const greenTarifCountriesAtom = atomWithDefault(get => {
  const party = get(partyAtom)

  if (!party) {
    return []
  }

  const allCountries = [party.location?.addressCountry, ...getAllowedCountries()]
  const filteredCountries = allCountries.filter(c => !GREEN_TARIFF_DISABLED_COUNTRIES.includes(c))

  return filteredCountries.filter(Boolean).slice(0, 5)
})
export const mapDataAtom = atom([])

const defaultDate = dayjs()

export const defaultInterval = {
  startMonth: defaultDate.format('MMMM'),
  startYear: defaultDate.year(),
  startDay: dayjs().subtract(1, 'day').date(),
  endMonth: defaultDate.format('MMMM'),
  endYear: defaultDate.year(),
  endDay: dayjs().subtract(1, 'day').date(),
}

export const aggrIntervalAtom = atomWithCompare(defaultInterval)
export const rawIntervalAtom = atomWithCompare(defaultInterval)

export const rawSpotPricesAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const countries = get(spotCountriesAtom)
    const interval = get(rawIntervalAtom)

    const startDate = `${interval.startYear}-${getMonthNumber(interval.startMonth) + 1}-${interval.startDay}T00:00:00`
    const endDate = `${interval.endYear}-${getMonthNumber(interval.endMonth) + 1}-${interval.endDay}T23:00:00`

    if (countries.length === 0) {
      return null
    }

    const data = await services.v2ContentService.getRawSpotPrices(party?.id, countries, startDate, endDate)

    return data
  }),
)

export const aggrSpotPricesAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const countries = get(spotCountriesAtom)
    const interval = get(aggrIntervalAtom)

    const isDailyPeriod = interval.startMonth === interval.endMonth
    const isToday = dayjs().date() === 1
    const endMonthNr = getMonthNumber(interval.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth()
    const isCurrentYear = interval.endYear === getCurrentYear()
    const endDay =
      isCurrentMonth && interval.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, interval.endYear) // Yesterday if current month and year
    const startDate = `${interval.startYear}-${getMonthNumber(interval.startMonth) + 1}-1T00:00:00`
    const endDate = `${interval.endYear}-${endMonthNr + 1}-${endDay}T23:00:00`
    const isFutureDate = isCurrentYear && endMonthNr > getCurrentMonth() && isDailyPeriod

    if (countries.length === 0) {
      return {intervals: null, uiError: null}
    }

    if (isFutureDate) {
      return {intervals: null, uiError: 'Period is in the future, please select another date'}
    }

    // We have only data for yesterday available
    if (isToday && isDailyPeriod && isCurrentMonth) {
      return {intervals: null, uiError: 'No data for this month, please select another date'}
    }

    const data = await services.v2ContentService.getAggrSpotPrices(
      party?.id,
      countries,
      startDate,
      endDate,
      isDailyPeriod ? Interval.DAILY : Interval.MONTHLY,
    )

    return {intervals: data?.intervals, uiError: null}
  }),
)

export const rawGreenTarifAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const countries = get(greenTarifCountriesAtom)
    const interval = get(rawIntervalAtom)

    const startDate = `${interval.startYear}-${getMonthNumber(interval.startMonth) + 1}-${interval.startDay}T00:00:00`
    const endDate = `${interval.endYear}-${getMonthNumber(interval.endMonth) + 1}-${interval.endDay}T23:00:00`

    if (countries.length === 0) {
      return null
    }

    const data = await services.v2ContentService.getRawGreenTarif(party?.id, countries, startDate, endDate)

    return data
  }),
)

export const aggrGreenTarifAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const countries = get(greenTarifCountriesAtom)
    const interval = get(aggrIntervalAtom)

    const isDailyPeriod = interval.startMonth === interval.endMonth
    const endMonthNr = getMonthNumber(interval.endMonth)
    const isCurrentMonth = endMonthNr === getCurrentMonth()
    const isCurrentYear = interval.endYear === getCurrentYear()
    const endDay =
      isCurrentMonth && interval.endYear === getCurrentYear()
        ? dayjs().date() - 1
        : lastDayOfMonth(endMonthNr + 1, interval.endYear) // Yesterday if current month and year
    const startDate = `${interval.startYear}-${getMonthNumber(interval.startMonth) + 1}-1T00:00:00`
    const endDate = `${interval.endYear}-${endMonthNr + 1}-${endDay}T23:00:00`
    const isFutureDate = isCurrentYear && endMonthNr > getCurrentMonth() && isDailyPeriod

    if (countries.length === 0) {
      return {intervals: null, uiError: null}
    }

    if (isFutureDate) {
      return {intervals: null, uiError: 'Period is in the future, please select another date'}
    }

    if (dayjs().date() === 1 && isDailyPeriod && isCurrentMonth) {
      return {
        intervals: null,
        uiError: 'The date you selected has just started, so there is no data yet. Please select another date',
      }
    }

    const data = await services.v2ContentService.getAggrGreenTarif(
      party?.id,
      countries,
      startDate,
      endDate,
      isDailyPeriod ? Interval.DAILY : Interval.MONTHLY,
    )

    return {intervals: data?.intervals, uiError: null}
  }),
)
