import React, {useState, useEffect} from 'react'
import Text from 'components/Text'
import Box from 'components/Box'
import Table from 'components/Table'
import {DateFormat, formatDate, getHourlyInterval} from 'helpers/date'
import {
  getAccreditationNum,
  getDateAndSuffix,
  getGeneralNumCertificates,
  getId,
  getNumCertificates,
} from 'helpers/certificates'
import {Chip} from '@mui/material'
import Checkbox from 'components/Checkbox'
import useLocalization from 'hooks/useLocalization'
import InnerScroll from 'components/InnerScroll'
import useTheme from 'hooks/useTheme'
import {IMarketListingReport} from 'domain/IMarketListing'
import {CertSchemeType} from 'domain/ICertificateOffer'

const getUkCertValue = (cert, showAccr, showDates) => {
  const accrNum = showAccr ? getAccreditationNum(cert) : ''
  const certId = getId(cert)
  const datesAndSuffix = showDates ? getDateAndSuffix(cert) : ''

  return `${accrNum}${certId}${datesAndSuffix}`
}

const getEuCertValue = (cert, overlap, showPrefix) => {
  if (showPrefix) {
    return cert
  }

  return cert.replace(overlap, '')
}

const getOverlapCertValue = cert => {
  const startCert = cert.start
  const endCert = cert.end
  let overlapCert = ''

  for (let i = 0; i < startCert.length; i++) {
    const startChar = startCert[i]
    const endChar = endCert[i]

    if (startChar === endChar && i !== startCert.length - 1) {
      overlapCert += startChar
    }
  }

  return overlapCert
}

export const TableCertValue = ({value, showAccr, showDates, showPrefix, isEuCert}) => {
  const theme = useTheme()

  if (!value) return '-'

  const overlappedValue = isEuCert && getOverlapCertValue(value)

  const startCert = isEuCert
    ? getEuCertValue(value.start, overlappedValue, showPrefix)
    : getUkCertValue(value.start, showAccr, showDates)
  const endCert = isEuCert
    ? getEuCertValue(value.end, overlappedValue, showPrefix)
    : getUkCertValue(value.end, showAccr, showDates)
  const totalCert = isEuCert
    ? getGeneralNumCertificates(value.start, value.end)?.value
    : getNumCertificates(value.start, value.end)

  return (
    <Box direction="row" align="center" gap={1}>
      <Box>
        <Box direction="row" align="center" justify="start">
          <Text bold size="msmall" style={{minWidth: 32}}>
            start:
          </Text>
          <Text size="medium" font={theme.font.primaryFont}>
            {startCert}
          </Text>
        </Box>

        <Box direction="row" align="center" justify="start">
          <Text bold size="msmall" style={{minWidth: 32}}>
            end:
          </Text>
          <Text size="medium" font={theme.font.primaryFont}>
            {endCert}
          </Text>
        </Box>
      </Box>
      <Box>
        <Chip color="primary" variant="outlined" label={`${totalCert} EAC`} />
      </Box>
    </Box>
  )
}

const MatchedCertsTable: React.FC<{data: IMarketListingReport}> = ({data}) => {
  const {translate} = useLocalization()
  const [showAccr, setShowAccr] = useState(true)
  const [showDates, setShowDates] = useState(true)
  const [showPrefix, setShowPrefix] = useState(true)

  const contracts = data?.hourly[0]?.contracts.map(contract => contract.id)
  const isEuCert = data?.hourly?.some(obj => obj?.total?.certScheme === CertSchemeType.EECS)

  return (
    <InnerScroll>
      <Box gap={3} direction="row" pad={{horizontal: 1}}>
        {isEuCert ? (
          <Checkbox
            label={translate('Show prefix numbers')}
            checked={showPrefix}
            onChange={() => setShowPrefix(!showPrefix)}
          />
        ) : (
          <>
            <Checkbox
              label={translate('Show accredation numbers')}
              checked={showAccr}
              onChange={() => setShowAccr(!showAccr)}
            />
            <Checkbox
              label={translate('Show dates and suffixes')}
              checked={showDates}
              onChange={() => setShowDates(!showDates)}
            />
          </>
        )}
      </Box>
      <Table
        tableName={translate('Certificates breakdown')}
        data={data?.hourly}
        sortable={false}
        columns={[
          {
            title: translate('Date'),
            accessor: report => report.timestamp,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {formatDate(date, DateFormat.MONTH_DAY_YEAR)}
              </Text>
            ),
          },
          {
            title: translate('Hour'),
            accessor: report => report.timestamp,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {getHourlyInterval(date)}
              </Text>
            ),
          },
          {
            title: translate('Total'),
            accessor: report => report.total,
            render: value => (
              <TableCertValue
                value={value}
                showAccr={showAccr}
                showDates={showDates}
                showPrefix={showPrefix}
                isEuCert={isEuCert}
              />
            ),
          },
          {
            title: translate('Gap'),
            accessor: report => report.gap,
            render: value => (
              <TableCertValue
                value={value}
                showAccr={showAccr}
                showDates={showDates}
                showPrefix={showPrefix}
                isEuCert={isEuCert}
              />
            ),
          },
          ...contracts.map(contract => ({
            title: translate(`Contract %s`, contract),
            accessor: report => {
              const contractData = report.contracts.find(c => c.id === contract)
              return contractData?.certs
            },
            render: value => (
              <TableCertValue
                value={value}
                showAccr={showAccr}
                showDates={showDates}
                showPrefix={showPrefix}
                isEuCert={isEuCert}
              />
            ),
          })),
        ].filter(Boolean)}
      />
    </InnerScroll>
  )
}

export default MatchedCertsTable
