import React from 'react'
import useLocalization from 'hooks/useLocalization'
import Form from 'components/Form'
import useServices from 'hooks/useServices'
import ICreatedUser from 'domain/ICreatedUser'
import {StyledFormButton, StyledFormCheckbox} from './Layout/FormComponents'
import Box from 'components/Box'
import Link from 'components/Link'
import styled from 'styled-components'
import FormInput from 'components/Form/FormInput'
import useAlerts from 'hooks/useAlerts'
import {UserRegisterKind} from 'services/V2ApiErrorMapper'
import FormPasswordInput from 'components/Form/FormPasswordInput'
import usePartyActions from 'hooks/usePartyActions'
import FormEmail from 'components/Form/FormEmail'
import FormName from 'components/Form/FormName'

interface IProps extends React.PropsWithChildren {
  onSuccess?: (data: ICreatedUser) => void
}

const StyledLink = styled(Link)`
  font-weight: 300;
  text-transform: uppercase;
  color: ${({theme}) => `${theme.colors.blue7}`};

  &:hover {
    text-decoration: underline;
  }
  font-family: ${({theme}) => `${theme.font.headings}`};
  color: ${({theme}) => `${theme.colors.primaryDark}`};
`

const CreateAccountForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {addError} = useAlerts()
  const {refreshParties} = usePartyActions()
  const {v2ContentService, authenticationService} = useServices()

  const handleSubmit = async ({firstName, lastName, email, password}) => {
    try {
      await v2ContentService.register({
        firstName,
        lastName,
        email,
        password,
      })
      await authenticationService.signIn(email, password)
      await refreshParties()
    } catch (e) {
      const kind = e?.error?.kind

      if (kind === UserRegisterKind.USER_BY_EMAIL_ALREADY_EXISTS) {
        addError(translate(e.message))
      } else {
        addError(translate('Something went wrong'), e?.correlationId, e?.message)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <FormName large defaultValue={''} name="firstName" label={translate('First name')} required data-cy="name" />
      <FormName large defaultValue={''} name="lastName" label={translate('Surname')} required data-cy="lastName" />
      <FormEmail large defaultValue={''} name="email" label={translate('Email address')} required data-cy="email" />
      <FormPasswordInput name="password" large />
      <FormInput
        large
        defaultValue={''}
        name="password2"
        label={translate('Confirm your password')}
        type="password"
        required
        validate={(value, getValues) => value === getValues().password || translate('Passwords do not match')}
        data-cy="pass2"
      />

      <StyledFormCheckbox
        name="agree"
        label={
          <StyledLink to="?modal.terms">
            {translate('I agree to the privacy policy and terms of the platform')}
          </StyledLink>
        }
        required
        data-cy="agree"
      />
      <StyledFormCheckbox
        name="cookies"
        label={<StyledLink to="?modal.cookies">{translate('I accept the cookie policy of the platform')}</StyledLink>}
        required
        data-cy="cookies"
      />
      <Box justify="flex-end" pad={{top: 2}}>
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default CreateAccountForm
