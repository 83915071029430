import axios from 'axios'
import ApiError from 'exceptions/ApiError'
import IAuthenticationService from './IAuthenticationService'
import IHttpClient from './IHttpClient'
import {jwtDecode} from 'jwt-decode'

export interface IApiClientOptions {
  errorMappingFn?: (response: any) => ApiError
}

export default class ApiClient {
  public static create(
    authenticationService: IAuthenticationService,
    apiURL: string,
    options: IApiClientOptions = {},
  ): IHttpClient {
    const httpClient = axios.create({
      baseURL: apiURL,
      timeout: 10000,
    })
    const sessionId = crypto.randomUUID()
    localStorage?.setItem('sessionId', sessionId)

    httpClient.interceptors.request.use(async config => {
      try {
        let token = await authenticationService.getToken()

        if (token !== undefined) {
          const decodedToken = jwtDecode(token)
          const expired = Date.now() + 5000 >= decodedToken.exp * 1000 // 5 seconds buffer

          if (expired) {
            token = await authenticationService.refreshToken()
          }
          config.headers.authorization = `Bearer ${token}`
        }

        if (!config.headers.Accept) {
          config.headers.Accept = 'application/json'
        }

        config.headers['X-UI-TRACE-ID'] = sessionId
        config.headers['X-UI-PATH'] = window.location.pathname + window.location.search

        return config
      } catch (error) {
        console.error('Failed to get token', error)
      }
    })

    httpClient.interceptors.response.use(undefined, error => {
      const {title, detail, message} = error?.response?.data || {}

      if (options?.errorMappingFn) {
        return Promise.reject(options.errorMappingFn(error?.response))
      }

      error.message = title || detail || message || error.message

      error.status = error?.response?.status
      error.errorType = error?.response?.data?.errorType

      return Promise.reject(error)
    })

    return httpClient
  }
}
