import React from 'react'
import FormInput from 'components/Form/FormInput'
import FormTextEditor from 'components/Form/FormTextEditor'
import FormTextArea from 'components/Form/FormTextArea'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import LocationSearch, {ILocationSearch} from '../../LocationSearch'
import SiteStateSelect from '../SiteStateSelect'
import ILocation from 'domain/ILocation'
import Box from 'components/Box'
import Heading from 'components/Heading'
import {SiteModel, SiteType} from 'domain/ISite'
import ProductionTechnologySelect from '../ProductionTechnologySelect'

interface IProps extends React.PropsWithChildren {
  location: ILocation
  setLocation: (location: ILocationSearch) => void
}

const SellerGeneral: React.FC<IProps> = ({location, setLocation}) => {
  const {translate} = useLocalization()

  return (
    <Box round border pad={2}>
      <FormInput name="siteType" type="hidden" defaultValue={SiteType.PRODUCTION} />
      <FormInput name="siteModel" type="hidden" defaultValue={SiteModel.PHYSICAL} />
      <FormInput name="name" label={translate('Name')} required defaultValue={null} />
      <FormInput
        name="meterReference"
        minLength={6}
        maxLength={25}
        placeholder={translate('Insert meter reference number')}
        label={translate('Meter reference number')}
        required
        disabled
        defaultValue={null}
      />
      <ProductionTechnologySelect name="productionTechnology" disabled />
      <SiteStateSelect name="state" required />
      <FormTextEditor name="description" label={translate('Description')} />
      <Label text={translate('Location')}>
        <LocationSearch
          setLocation={setLocation}
          location={location}
          title="Location"
          variant="primary"
          placeholder={translate('Start typing the address')}
        />
      </Label>
      <FormTextArea name="locationDetails" label={translate('Location details')} />
      <FormInput
        required
        type="date"
        name="operationsStart"
        label={translate('Operations start')}
        defaultValue={null}
      />
      <FormInput type="date" name="operationsEnd" label={translate('Operations end')} defaultValue={null} />
    </Box>
  )
}

export default SellerGeneral
