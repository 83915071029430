import React from 'react'
import styled from 'styled-components'
import Text from './Text'
import Box from './Box'
import {Tooltip} from '@mui/material'

const StyledLabel = styled.label<{left?: string; position?: string; top?: string; color?: string}>`
  display: block;
  ${props =>
    props.position &&
    props.left &&
    props.top &&
    `
    position: absolute;
    left: ${props.left};
    top: ${props.top};
  `}
  color: ${props => props.color};
`

const LabelText = styled(Text).attrs({
  size: 'small',
  uppercase: true,
})`
  margin-bottom: 5px;
`

interface IProps extends React.PropsWithChildren {
  text: React.ReactNode
  position?: 'absolute'
  left?: string
  top?: string
  color?: string
}

const Label: React.FC<IProps> = ({text, position, left, top, children, color}) => (
  <StyledLabel left={left} top={top} position={position} color={color}>
    <LabelText>{text}</LabelText>
    {children}
  </StyledLabel>
)

export const LabelWithIcon: React.FC<IProps & {Icon: React.ReactElement; tooltip: string}> = ({
  text,
  position,
  left,
  top,
  Icon,
  tooltip,
  children,
}) => (
  <StyledLabel left={left} top={top} position={position}>
    <Box direction="row" align="center" gap={1}>
      <LabelText>{text}</LabelText>
      <Tooltip title={tooltip}>
        <div>
          <Box>{Icon}</Box>
        </div>
      </Tooltip>
    </Box>
    {children}
  </StyledLabel>
)

export interface IWithLabelProps {
  label?: string
}

export const withLabel = <T extends Record<string, any>>(Component: any) => {
  // eslint-disable-next-line react/display-name
  return React.forwardRef(({label, ...props}: IWithLabelProps & T, ref: React.Ref<any>) => {
    return label ? (
      <Label text={label}>
        <Component {...(props as T)} ref={ref} />
      </Label>
    ) : (
      <Component {...(props as T)} ref={ref} />
    )
  }) as unknown as React.FC<IWithLabelProps & T>
}

export default Label
