import {FormControlLabel, RadioGroup} from '@mui/material'
import {uploadGenerationAtom} from 'atoms/marketListings'
import Box from 'components/Box'
import Form from 'components/Form'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import FormRange from 'components/Form/FormRange'
import FormSelect from 'components/Form/FormSelect'
import Heading from 'components/Heading'
import Label from 'components/Label'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import ValueWithUnit from 'components/ValueWithUnit'
import {IUploadGenerationFormData} from 'domain/IMarketListing'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import {getMonths, getPastYears, getPrevMonth, getPrevMonthYear} from 'helpers/date'
import {getChangeEventValue} from 'helpers/misc'
import useLocalization from 'hooks/useLocalization'
import {useSetAtom} from 'jotai'
import React, {useState} from 'react'
import DownloadExampleLink from 'components/Platform/Portfolio/Site/Form/DownloadExampleLink'
import Radio from 'components/Radio'

interface UploadGenerationProps {
  uploadType: TimeseriesKind
  setUploadType: (uploadType: TimeseriesKind) => void
  volume: number
  setVolume: (volume: number) => void
  formRef: React.RefObject<HTMLFormElement>
  onSubmit: () => void
}

export const UploadGeneration = ({
  uploadType,
  setUploadType,
  volume,
  setVolume,
  formRef,
  onSubmit,
}: UploadGenerationProps) => {
  const {translate} = useLocalization()
  const months = getMonths()
  const years = getPastYears(2)
  const setUploadGeneration = useSetAtom(uploadGenerationAtom)

  const [interval, setInterval] = useState({
    month: getPrevMonth(),
    year: getPrevMonthYear(),
  })

  const handleSubmit = (data: IUploadGenerationFormData) => {
    setUploadGeneration(data)
    onSubmit()
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef} submissionFeedback={null}>
      <Box pad={2} round border>
        <Heading margin={{bottom: 1}} size="msmall">
          {translate('2. Upload generation')}
        </Heading>
        <RadioGroup row value={uploadType} onChange={e => setUploadType(getChangeEventValue(e))}>
          <FormControlLabel value={TimeseriesKind.BUILDER} control={<Radio />} label={translate('Build')} />
          <FormControlLabel
            data-cy="radio-upload"
            value={TimeseriesKind.FILE_UPLOAD}
            control={<Radio />}
            label={translate('Upload')}
          />
        </RadioGroup>
        <Label text={translate('Interval')}>
          <Box direction="row" gap={1} pad={{bottom: 2}} width="calc(50% - 8px)">
            <FormSelect
              style={{width: '100%'}}
              required
              name="interval.month"
              margin={0}
              options={months.map((month, index) => ({value: month, label: month}))}
              placeholder="Month"
              defaultValue={getPrevMonth()}
              onChange={value => setInterval({...interval, month: value})}
            />
            <FormSelect
              style={{width: '100%'}}
              width="100%"
              required
              name="interval.year"
              margin={0}
              options={years.map(year => ({value: year, label: `${year}`}))}
              placeholder="Year"
              defaultValue={getPrevMonthYear()}
              onChange={value => setInterval({...interval, year: +value})}
            />
          </Box>
        </Label>
        {uploadType === TimeseriesKind.BUILDER && <FormProfileBuilder production name="generation.builder" />}
        {uploadType === TimeseriesKind.FILE_UPLOAD && (
          <>
            <FormAttachmentUpload
              label={translate('Upload profile')}
              name="generation.profileAttachment"
              removable
              required
            />

            <Box margin={{vertical: 2}}>
              <DownloadExampleLink
                interval={{month: interval.month, year: +interval.year}}
                siteType={SiteType.CONSUMPTION}
              />
            </Box>
          </>
        )}

        <Box gap={1} margin={{top: 2}} direction="column" width="50%">
          <FormRange
            label={translate('Generation volume dedicated to the marketplace')}
            name="generation.volumePercent"
            min={1}
            max={100}
            step={1}
            defaultValue={100}
            onChange={value => setVolume(+value)}
            data-cy="listing-volume"
          />

          <Label text={translate('Volume')}>
            <ValueWithUnit size="large" value={volume} unit={'%'} />
          </Label>
        </Box>
      </Box>
    </Form>
  )
}
