import {Country} from './ILocation'
import {SiteProductionTechnology} from './ISite'

export interface ICreatePpaTenderReq {
  technologies: SiteProductionTechnology[]
  countries: Country[]
  state: PpaTenderRequestState
  subsidiesStatus: SubsidiesStatus
  maxAgeYears: number
  deliveryStartsOn: string
  deliveryEndsOn: string
  deliveryPeriodComment?: string
  annualVolumeMwh: number
  deliveryPreference: DeliveryPreference
  ppaKind: PpaKind
  priceStructure: PriceStructure
  escalation: Escalation
  balancingPrefInPrice: boolean
  certsInPrice: boolean
}

export enum PpaTenderRequestState {
  NEW_BUILD = 'NEW_BUILD',
  OPERATIONAL = 'OPERATIONAL',
  ANY = 'ANY',
}

export enum SubsidiesStatus {
  SUBSIDIES = 'SUBSIDIES',
  NOT_SUBSIDIES = 'NOT_SUBSIDIES',
}

export enum DeliveryPreferenceOptions {
  PAY_AS_PRODUCED = 'PAY_AS_PRODUCED',
  CUSTOM = 'CUSTOM',
}

export type DeliveryPreference = 'PAY_AS_PRODUCED' | {CUSTOM: {comment: string}}

export enum PpaKind {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
  ANY = 'ANY',
}

export enum PriceStructure {
  FULLY_FIXED = 'FULLY_FIXED',
  FIXED_WITH_ANNUAL_ESCALATION = 'FIXED_WITH_ANNUAL_ESCALATION',
  BALANCING_PREFERENCE = 'BALANCING_PREFERENCE',
}

export enum Escalation {
  CPI = 'CPI',
  FIXED = 'FIXED',
}
