import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import Label from './Label'
import ITheme from '../theme/ITheme'
import InputError from './InputError'
import dayjs from 'dayjs'
import {getChangeEventValue} from '../helpers/misc'
import spacing, {ISpacingProps} from './styleProps/spacing'
import {getMonthNumber} from 'helpers/date'

interface IContainerProps extends ISpacingProps {
  hasError?: boolean
  height?: number
  large?: boolean
  small?: boolean
}

export const InputContainer = styled.div<IContainerProps>`
  position: relative;
  display: flex;
  border: 1px solid
    ${props => (props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.lightGrey)};
  padding: 8px 12px;
  font-size: 14px;
  outline: 0;
  color: ${props => (props.theme as ITheme).colors.secondary};
  background-color: ${props => (props.theme as ITheme).colors.surface};
  height: ${props => `${(props.theme as ITheme).tileSize * props.height || 40}px`};
  height: ${props => props.large && '56px'};
  height: ${props => props.small && '32px'};
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
  min-width: 0;
  font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
  font-weight: 200;
  ${spacing}

  &:hover {
    border-color: ${props =>
      props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.secondary};
  }

  &:focus-within {
    border-color: ${props =>
      props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.primary};
  }

  input,
  textarea {
    font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
    font-weight: 200;
    padding: 0;
    margin: 0;
    height: 100%;
    flex-grow: 1;
    font-size: 14px;
    color: ${props => (props.theme as ITheme).colors.secondary};
    background: transparent;
    border: 0;
    &::placeholder {
      font-size: 14px;
      color: #999;
    }

    &::-webkit-clear-button {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: ${props => (props.theme as ITheme).colors.nGrey3};
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    margin: 0px;
    padding: 0px;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }
`

InputContainer.defaultProps = {
  height: 5,
  margin: {bottom: 2},
}

type IInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'height'
>

export interface IWrapProps
  extends React.PropsWithChildren,
    ISpacingProps,
    Pick<React.HTMLProps<HTMLDivElement>, 'style'> {
  label?: string
  labelColor?: string
  error?: string
  height?: number
  large?: boolean
  small?: boolean
  className?: any
  isSubmitted?: boolean
  errorType?: string
}

export interface IProps
  extends React.PropsWithChildren,
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'height'>,
    IWrapProps {
  prefix?: any
  suffix?: any
  disabled?: any
  value?: any
  min?: any
  max?: any
  className?: any
  openOnClick?: boolean
  labelColor?: string
}

export const InputWrap: React.FC<IWrapProps & {onClick?: (e: React.MouseEvent<HTMLDivElement>) => void}> = ({
  margin,
  children,
  label,
  labelColor,
  height,
  error = null,
  onClick,
  ...rest
}) => {
  const content = (
    <InputContainer
      onClick={onClick}
      margin={margin}
      errorMargin={!!error && 1}
      height={height}
      hasError={!!error}
      {...rest}
    >
      {children}
      {error && <InputError>{error}</InputError>}
    </InputContainer>
  )

  if (label) {
    return (
      <Label text={label} color={labelColor}>
        {content}
      </Label>
    )
  }

  return content
}

// TODO: Use MUI for it
const DateInput: React.FC<IInputProps & {openOnClick?: boolean}> = ({
  value,
  onChange,
  min,
  max,
  openOnClick,
  ...props
}) => {
  const [input, setInput] = useState(value)
  const dateRef = useRef<HTMLInputElement>()
  const parse = value => value && dayjs(value as string).format('YYYY-MM-DD')

  // For setting the default value of the form(it doesn't translate to actual defaultValue)
  useEffect(() => {
    if (!input && value) {
      setInput(value)
    }
  }, [value])

  const handleChange = e => {
    const value = getChangeEventValue(e)
    const date = dayjs(value)
    setInput(value)

    if (date.isValid()) {
      onChange && onChange(date.toISOString() as any)
    }
  }

  const handleClick = () => {
    openOnClick && dateRef.current.showPicker()
  }

  return (
    <input
      onClick={handleClick}
      ref={dateRef}
      {...props}
      min={parse(min)}
      max={parse(max)}
      value={input}
      onChange={handleChange}
      type="date"
    />
  )
}

const DayInput: React.FC<IInputProps> = ({value, onChange, min, max, ...props}) => {
  const dateRef = useRef<HTMLInputElement>()
  const ref = useRef<HTMLInputElement>()
  const {day, month, year} = (value as any) || {}
  const parsedValue = dayjs().month(getMonthNumber(month)).year(year)
  const minDate = dayjs(parsedValue).date(1).format('YYYY-MM-DD')
  const maxDate = dayjs(parsedValue).date(parsedValue.daysInMonth()).format('YYYY-MM-DD')
  const handleChange = date => {
    const formattedDate = dayjs(getChangeEventValue(date))

    onChange && onChange((formattedDate.date() as any) || undefined)
  }

  const handleClick = () => {
    dateRef.current.showPicker()
  }

  return (
    <>
      <input
        style={{maxWidth: 0}}
        ref={dateRef}
        defaultValue={day && parsedValue.date(day).format('YYYY-MM-DD')}
        min={minDate}
        max={maxDate}
        onChange={handleChange}
        type="date"
      />
      <input
        {...props}
        onClick={handleClick}
        value={day ? parsedValue.date(day).format('DD') : 'All'}
        readOnly
        ref={ref}
      />
    </>
  )
}

// eslint-disable-next-line react/display-name
const Input: React.FC<IProps> = React.forwardRef((props, ref: React.Ref<any>) => {
  const {
    label,
    error = null,
    errorType,
    prefix,
    suffix,
    height,
    type,
    margin,
    value: initialValue,
    style,
    large,
    small,
    isSubmitted,
    labelColor,
    openOnClick,
    ...inputProps
  } = props
  const value = initialValue === null ? '' : initialValue

  let input

  if (type === 'date') {
    input = <DateInput {...inputProps} value={value} ref={ref} openOnClick={openOnClick} />
  } else if (type === 'day') {
    input = <DayInput {...inputProps} value={value} ref={ref} />
  } else {
    input = <input autoComplete="off" {...inputProps} value={value} type={type} ref={ref} />
  }

  if (type === 'hidden') {
    return input
  }

  return (
    <InputWrap
      error={error}
      label={label}
      height={height}
      large={large}
      small={small}
      margin={margin}
      style={style}
      labelColor={labelColor}
      className={props?.className}
    >
      {prefix}
      {input}
      {suffix}
    </InputWrap>
  )
})

export default Input
