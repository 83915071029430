import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from 'components/Chart'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import {IContractedVolumeTimeseries} from 'domain/Portfolio'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  contractsTimeseries: IContractedVolumeTimeseries
}

const ExternalContractProfile: React.FC<IProps> = ({contractsTimeseries}) => {
  const chartColors = useNewChartColors(8)

  const labels = contractsTimeseries?.timeseries.map(({name}) => name) || []

  const lines: ILine[] = []

  lines.push({
    key: contractsTimeseries?.contractId,
    label: `External-#${contractsTimeseries?.contractId}`,
    color: chartColors.getNext(),
    data: contractsTimeseries?.timeseries.map(({value}) => value),
    type: ChartType.BAR,
    stackType: StackType.STACKED,
  })

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: true, hideMonthly: true}}}
      labels={labels}
      lines={lines}
      dark
      typeLabels={{
        [StackType.DEFAULT]: 'EACs',
      }}
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default ExternalContractProfile
