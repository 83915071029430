import React from 'react'
import FormInput, {IProps} from './FormInput'
import useLocalization from '../../hooks/useLocalization'

const FormName: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return (
    <FormInput
      pattern={{
        value: /^[^\s]+$/,
        message: translate('Spaces not allowed'),
      }}
      {...props}
    />
  )
}

export default FormName
