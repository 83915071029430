import {StepLabel, Stepper, Step} from '@mui/material'
import Box from 'components/Box'
import Button from 'components/Button'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import {TimeseriesKind} from 'domain/ISite'
import useLocalization from 'hooks/useLocalization'
import React, {useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Certificate} from './MarkeplaceListingSteps/Certificate'
import {SelectProduct} from './MarkeplaceListingSteps/SelectProduct'
import {UploadGeneration} from './MarkeplaceListingSteps/UploadGeneration'
import useTheme from 'hooks/useTheme'
interface IProps extends React.PropsWithChildren {}

enum ListingSteps {
  SELECT_PRODUCT = 0,
  LISTING_DETAILS = 1,
  CERTIFICATION = 2,
}

const AddMarketplaceListingPage: React.FC<IProps> = () => {
  const location = useLocation()
  const theme = useTheme()
  const urlSearchParams = new URLSearchParams(location.search)
  const urlProductId = urlSearchParams.get('productId')
  const {translate} = useLocalization()
  const [newProduct, setNewProduct] = useState(false)
  const [uploadType, setUploadType] = useState<TimeseriesKind>(TimeseriesKind.BUILDER)
  const [newSite, setNewSite] = useState(false)
  const [activeStep, setActiveStep] = useState<ListingSteps>(ListingSteps.SELECT_PRODUCT)
  const [volume, setVolume] = useState(100)

  const productFormRef = useRef<HTMLFormElement>(null)
  const listingFormRef = useRef<HTMLFormElement>(null)
  const certificateFormRef = useRef<HTMLFormElement>(null)

  const handleRadioChange = (event, isProduct) => {
    const value = event.target.value
    const isTrue = value === 'true'

    if (isProduct) {
      setNewProduct(isTrue)
    } else {
      setNewSite(isTrue)
    }
  }

  const handleNext = () => {
    handleSave()
  }

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1)
  }

  const handleSave = () => {
    if (activeStep === ListingSteps.SELECT_PRODUCT) {
      productFormRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    } else if (activeStep === ListingSteps.LISTING_DETAILS) {
      listingFormRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    } else if (activeStep === ListingSteps.CERTIFICATION) {
      certificateFormRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    }
  }

  const stepSx = {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color: theme.colors.common.black,
    },
    '& .MuiStepLabel-root .Mui-completed': {
      color: theme.colors.green2,
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
      color: theme.colors.common.black,
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: theme.colors.green2,
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
      color: theme.colors.common.black,
    },
  }

  return (
    <Page
      title={translate('Add marketplace listing')}
      description={translate('Manage information about the marketplace listing')}
      noDivider
    >
      <InnerScroll noRightPad>
        <Box pad={{bottom: 2}}>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step sx={stepSx}>
              <StepLabel>{translate('Select Product')}</StepLabel>
            </Step>
            <Step sx={stepSx}>
              <StepLabel>{translate('Listing Details')}</StepLabel>
            </Step>
            <Step sx={stepSx}>
              <StepLabel>{translate('Certificate')}</StepLabel>
            </Step>
          </Stepper>

          <Box margin={{top: 4}}>
            <Box style={{display: activeStep === ListingSteps.SELECT_PRODUCT ? 'block' : 'none'}}>
              <SelectProduct
                formRef={productFormRef}
                newProduct={newProduct}
                handleRadioChange={handleRadioChange}
                newSite={newSite}
                productId={urlProductId}
                onSubmit={() => setActiveStep(prevStep => prevStep + 1)}
              />
            </Box>

            <Box style={{display: activeStep === ListingSteps.LISTING_DETAILS ? 'block' : 'none'}}>
              <UploadGeneration
                formRef={listingFormRef}
                uploadType={uploadType}
                setUploadType={setUploadType}
                volume={volume}
                setVolume={setVolume}
                onSubmit={() => setActiveStep(prevStep => prevStep + 1)}
              />
            </Box>

            {activeStep === ListingSteps.CERTIFICATION && (
              <Certificate
                formRef={certificateFormRef}
                volume={volume}
                newProduct={newProduct}
                newSite={newSite}
                uploadType={uploadType}
              />
            )}
          </Box>
        </Box>
      </InnerScroll>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '30px',
          paddingBottom: '16px',
          alignSelf: 'flex-end',
        }}
      >
        <Button disabled={activeStep === ListingSteps.SELECT_PRODUCT} onClick={handleBack} data-cy="back-page-button">
          {translate('Back')}
        </Button>

        {activeStep === ListingSteps.CERTIFICATION ? (
          <Button data-cy="save-button" onClick={handleSave}>
            {translate('Save')}
          </Button>
        ) : (
          <Button data-cy="next-page-button" variant="primary" onClick={handleNext}>
            {translate('Next')}
          </Button>
        )}
      </Box>
    </Page>
  )
}

export default AddMarketplaceListingPage
