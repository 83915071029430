import {FormControlLabel, RadioGroup} from '@mui/material'
import {rootServiceAtom} from 'atoms/general'
import {basicProductsAtom, basicSitesAtom, certificateLocationAtom, selectProductAtom} from 'atoms/marketListings'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Form from 'components/Form'
import FormSelect from 'components/Form/FormSelect'
import Heading from 'components/Heading'
import Radio from 'components/Radio'
import {ISelectProductFormData} from 'domain/IMarketListing'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import ProductForm from '../ProductForm'
import ILocation from 'domain/ILocation'

interface SelectProductProps {
  formRef: React.RefObject<HTMLFormElement>
  newProduct: boolean
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>, newProduct: boolean) => void
  newSite: boolean
  productId: string
  onSubmit: () => void
}

export const SelectProduct = ({
  formRef,
  newProduct,
  handleRadioChange,
  newSite,
  productId,
  onSubmit,
}: SelectProductProps) => {
  const {translate} = useLocalization()
  const setSelectProduct = useSetAtom(selectProductAtom)
  const setCertificateLocation = useSetAtom(certificateLocationAtom)
  const {data: products} = useAtomValue(basicProductsAtom)
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {data: sites} = useAtomValue(basicSitesAtom)

  const handleSubmit = async (data: ISelectProductFormData) => {
    setSelectProduct(data)
    if (!newProduct) {
      const location = await rootService.v2ContentService.getProductLocation(party.id, data.product.id)
      setCertificateLocation({
        placeId: location.placeId,
        addressCountry: location.addressCountry,
      })
    } else if (!newSite) {
      const site = sites.find(site => site.id.toString() === data.site.id)
      const siteLocation = site?.location
      setCertificateLocation({
        placeId: siteLocation.placeId,
        addressCountry: siteLocation.addressCountry,
      })
    } else {
      setCertificateLocation({
        placeId: data.location.placeId,
        addressCountry: data.location.countryCode,
      })
    }

    onSubmit()
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef} submissionFeedback={null}>
      <Box pad={2} round border>
        <Heading margin={{bottom: 1}} size="msmall">
          {translate('1. Select or create product')}
        </Heading>
        <RadioGroup row value={newProduct} onChange={e => handleRadioChange(e, true)}>
          <FormControlLabel value={false} control={<Radio />} label={translate('Existing product')} />
          <FormControlLabel
            data-cy="new-product-radio"
            value={true}
            control={<Radio />}
            label={translate('New product')}
          />
        </RadioGroup>
        {newProduct ? (
          <ProductForm newSite={newSite} handleRadioChange={handleRadioChange} />
        ) : (
          <FormSelect
            placeholder={translate('Select product')}
            name="product.id"
            label={translate('Choose product')}
            required
            defaultValue={productId || ''}
            data-cy="product-select"
            options={products?.map(product => ({
              value: product.id,
              label: product.name,
            }))}
          />
        )}
      </Box>
    </Form>
  )
}
