import React, {useEffect} from 'react'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {Tooltip} from '@mui/material'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Button from 'components/Button'
import {FeatureFlags} from 'components/FeatureFlagProvider'
import FormCertInput from 'components/Form/FormCertInput'
import FormInput from 'components/Form/FormInput'
import Label from 'components/Label'
import Text from 'components/Text'
import ValueWithUnit from 'components/ValueWithUnit'
import {IGenerateTestCertsPayload, MarketListingKind} from 'domain/IMarketListing'
import {SiteProductionTechnology, TimeseriesKind} from 'domain/ISite'
import {getAccreditationNum, getNumCertificates} from 'helpers/certificates'
import useAlerts from 'hooks/useAlerts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useFormContext from 'hooks/useFormContext'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue} from 'jotai'
import {useState} from 'react'
import theme from 'theme'
import {certificateLocationAtom, selectProductAtom, uploadGenerationAtom} from 'atoms/marketListings'

interface IProps {
  uploadType: TimeseriesKind
  newProduct: boolean
  volume: number
}

export const UkCertificate = ({uploadType, newProduct, volume}: IProps) => {
  const {translate} = useLocalization()
  const {getValues} = useFormContext()
  const [staticStartCert, setStaticStartCert] = useState('')
  const [staticEndCert, setStaticEndCert] = useState('')
  const [disabledCerts, setDisabledCerts] = useState(false)
  const {listing} = getValues({nest: true})
  const {getFeatureFlag} = useFeatureFlags()
  const {addError} = useAlerts()
  const allowGenerateCert = getFeatureFlag(FeatureFlags.ENABLE_CERT_GENERATION)
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const uploadGeneration = useAtomValue(uploadGenerationAtom)
  const selectedProduct = useAtomValue(selectProductAtom)
  const certificateLocation = useAtomValue(certificateLocationAtom)

  const {generation, interval} = uploadGeneration || {}
  const {profileAttachment, builder} = generation || {}

  const {technology, id: siteId} = selectedProduct?.site || {}
  const {id: productId, name: productName} = selectedProduct?.product || {}

  const {firstCertificate: startCert, lastCertificate: endCert} = listing || {}
  const certVolume = getNumCertificates(startCert, endCert)
  const validCerts = certVolume !== null
  const invalidAccNum = validCerts ? getAccreditationNum(startCert) !== getAccreditationNum(endCert) : false
  const hasTimeseries = uploadType === TimeseriesKind.BUILDER || profileAttachment?.id
  const hasProduct = !newProduct && productId
  const hasSite = newProduct && (technology || siteId)

  const disableCertGenButton = !allowGenerateCert || !hasTimeseries || !(hasProduct || hasSite)

  useEffect(() => {
    setStaticStartCert('')
    setStaticEndCert('')
  }, [interval?.year, interval?.month, volume, productId, productName, siteId, technology, listing?.builder])

  const handleGenerateCertificates = async () => {
    try {
      const sitePayload = siteId
        ? {kind: MarketListingKind.EXISTING as const, payload: {id: +siteId}}
        : {
            kind: MarketListingKind.NEW as const,
            payload: {technology: technology as SiteProductionTechnology, placeId: certificateLocation.placeId},
          }

      const payload: IGenerateTestCertsPayload = {
        product: newProduct
          ? {
              kind: MarketListingKind.NEW as const,
              payload: {
                site: sitePayload,
              },
            }
          : {
              kind: MarketListingKind.EXISTING as const,
              payload: {
                id: +productId,
              },
            },
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder?.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? profileAttachment?.id : undefined,
          },
        },
      }
      const certs = await rootService.v2ContentService.generateMarketListingTestCertificates(party.id, payload)
      setDisabledCerts(true)
      setStaticStartCert(certs.start)
      setStaticEndCert(certs.end)
    } catch (ex) {
      addError('Failed to generate test certificates', ex?.correlationId, ex?.message)
    }
  }

  return (
    <Box>
      <FormInput
        name="listing.price"
        label={translate('Price')}
        type="number"
        defaultValue={''}
        placeholder={translate('Insert price as number with max two decimals')}
        data-cy="listing-price"
        required
      />

      {allowGenerateCert && (
        <Box justify="end" margin={{top: 2, bottom: -4}}>
          <Button
            style={{zIndex: 99}}
            small
            type="button"
            variant="primary"
            onClick={handleGenerateCertificates}
            data-cy="generate-certs"
            disabled={disableCertGenButton}
          >
            <Tooltip
              title={disableCertGenButton ? translate('Previous steps should be completed') : undefined}
              arrow
              placement="bottom"
            >
              <div>
                <Box direction="row" gap={1}>
                  <AutoAwesomeIcon />
                  <Text>{translate('Generate test certificates')}</Text>
                  <AutoAwesomeIcon />
                </Box>
              </div>
            </Tooltip>
          </Button>
        </Box>
      )}

      <FormCertInput
        disabled={disabledCerts}
        required
        name="listing.firstCertificate"
        staticValue={disabledCerts && staticStartCert}
        label={translate('Start certificate number')}
      />
      <FormCertInput
        disabled={disabledCerts}
        staticValue={disabledCerts && staticEndCert}
        required
        name="listing.lastCertificate"
        label={translate('End certificate number')}
      />
      {validCerts && invalidAccNum && (
        <Text size="mlarge" color={theme.colors.error}>
          {translate('The accreditation number of start and end do not match')}
        </Text>
      )}

      <Box margin={{vertical: 2}} data-cy="certificate-volume-label">
        <Label text={translate('Certificate volume')}>
          <ValueWithUnit size="large" value={certVolume || '-'} unit={certVolume ? 'EACs' : ''} />
        </Label>
      </Box>
    </Box>
  )
}
