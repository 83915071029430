import React from 'react'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import Heading from 'components/Heading'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {performanceAtom, overviewDateFilter} from 'atoms/portfolioOverview'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import useTheme from 'hooks/useTheme'
import {CurrencyCode} from 'domain/IPrice'
import PerformanceChart from 'components/Platform/Portfolio/Performance/PerformanceChart'
import PerformanceTable from 'components/Platform/Portfolio/Performance/PerformanceTable'
import PerformanceLoadingSkeleton from 'components/Platform/Portfolio/Performance/PerformanceLoadingSkeleton'
import PeriodSelect from 'components/PeriodSelect'
import {overviewDateFiltermAtom} from 'atoms/portfolioOverview'

const PerformanceOverview: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {data, loading, error} = useAtomValue(performanceAtom)
  const [dateFilter, setDateFilter] = useAtom(overviewDateFilter)
  const setOverviewDateFilter = useSetAtom(overviewDateFiltermAtom)
  const {translate} = useLocalization()
  const hasData = data?.contractsTimeseries.length > 0
  const {generationMwh, profitCurrency, totalProfit, averageProfit} = data?.summary || {}
  const noVolumeForPeriod = data?.contracts.every(contract => contract.volumeMwh === 0)

  if (loading) {
    return <PerformanceLoadingSkeleton />
  }

  return (
    <Box pad={{bottom: 2}}>
      <PeriodSelect setValue={setDateFilter} onMonthChange={(month, year) => setOverviewDateFilter({month, year})} />
      {!noVolumeForPeriod && (
        <ResourceSummary noPad>
          <ResourceSummaryItem
            title={translate('Consumption in selected period')}
            value={generationMwh}
            color={theme.colors.green1}
            showFilledProgress
            unit={translate('MWh')}
          />
          <ResourceSummaryItem
            title={translate('Total profit')}
            value={totalProfit}
            color={theme.colors.yellow1}
            showFilledProgress
            unit={CurrencyCode[profitCurrency]}
          />
          <ResourceSummaryItem
            title={translate('Average profit per mwh')}
            value={averageProfit}
            color={theme.colors.blue2}
            showFilledProgress
            unit={CurrencyCode[profitCurrency]}
          />
        </ResourceSummary>
      )}

      {error ? (
        <Box justify="center">
          <Heading margin={{top: 4}}>{translate('Failed to load Performance info')}</Heading>
        </Box>
      ) : (
        <InnerScroll noRightPad>
          {!hasData ? (
            <Box justify="center">
              <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
            </Box>
          ) : (
            <>
              <Box pad={{vertical: 3}}>
                {!noVolumeForPeriod ? (
                  <PerformanceChart
                    data={data.contractsTimeseries}
                    namesById={data.contracts.reduce((acc, {id, name}) => ({...acc, [id]: name}), {})}
                  />
                ) : (
                  <Box justify="center">
                    <Heading margin={{top: 2, bottom: 2}}>
                      {translate(
                        'No external contract data present or EAC purchases have occurred in the selected period',
                      )}
                    </Heading>
                  </Box>
                )}
              </Box>
              <Heading>{translate('Contract performance')}</Heading>
              <PerformanceTable
                contracts={data.contracts}
                currency={data.summary.profitCurrency}
                dateFilter={dateFilter}
              />
            </>
          )}
        </InnerScroll>
      )}
    </Box>
  )
}

export default PerformanceOverview
