import React, {useEffect, useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import useServices from 'hooks/useServices'
import Form from 'components/Form'
import Text from 'components/Text'
import TextLink from 'components/TextLink'
import Box from 'components/Box'
import FormInput from 'components/Form/FormInput'
import {StyledFormButton} from './Layout/FormComponents'
import useAlerts from 'hooks/useAlerts'
import {UserRegisterKind} from 'services/V2ApiErrorMapper'
import usePartyActions from 'hooks/usePartyActions'

interface ParamTypes {
  email: string
}

interface IProps extends React.PropsWithChildren {}

let timeout

const EmailVerificationForm: React.FC<IProps> = () => {
  const {addError} = useAlerts()
  const [showResend, setShowResend] = useState(false)
  const {refreshParties} = usePartyActions()
  const {translate} = useLocalization()
  const {v2ContentService} = useServices()

  const handleSubmit = async ({code}, {setError}) => {
    try {
      await v2ContentService.confirmEmail(code.trim())
      await refreshParties()
    } catch (error) {
      const kind = error?.error?.kind
      if (kind in UserRegisterKind) {
        addError(translate(error.message))
      } else {
        addError('Failed to verify code', error?.correlationId, error?.message)
      }
    }
  }

  useEffect(() => {
    timeout = setTimeout(() => {
      setShowResend(true)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [showResend])

  const handleResend = async () => {
    setShowResend(false)
    await v2ContentService.resendConfirmationEmail()
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <FormInput
        large
        placeholder="xxxxxx"
        label={translate('Verification code')}
        validate={value => {
          const trimmedValue = value.trim()

          return /^\d{6}$/.test(trimmedValue) || translate('Please enter exactly 6 digits')
        }}
        name="code"
        required
        data-cy="code"
      />
      <Box justify="space-between" pad={{top: 1}} align="center">
        {showResend ? (
          <Text bold>
            {translate('Didn’t receive an email?')}{' '}
            <TextLink onClick={handleResend}>{translate('Click here to resend')}</TextLink>
          </Text>
        ) : (
          <Box />
        )}
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default EmailVerificationForm
