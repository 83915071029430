import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'
import INewTimeseriesItem from '../../../../domain/INewTimeseriesItem'
import {autoCeilNumber, autoFloorNumber} from '../../../../helpers/misc'
import useTheme from 'hooks/useTheme'
import {capitalize} from 'lodash-es'

interface IProps extends React.PropsWithChildren {
  certData: INewTimeseriesItem
  filter?: {showExcess: boolean; showMisisng: boolean}
}

const getLineLabel = (key: string) => {
  if ('totalProduction' === key) {
    return 'Total production'
  }
  if ('reservedCertProduction' === key) {
    return 'Purchased certificates'
  }
  if ('deliveredPpaProduction' === key) {
    return 'Contracted'
  }
  if ('missing' === key) {
    return 'Shortfall'
  }

  return capitalize(key)
}

const CertificatesChart: React.FC<IProps> = ({certData, filter}) => {
  const theme = useTheme()
  const {showExcess, showMisisng} = filter || {}
  const {missing, excess, consumption, totalProduction, reservedCertProduction, deliveredPpaProduction} = certData || {}
  const dataInOrder = {
    reservedCertProduction: {
      value: reservedCertProduction,
      color: theme.colors.green1,
    },
    deliveredPpaProduction: {value: deliveredPpaProduction, color: theme.colors.green2},
    missing: {value: missing, color: theme.colors.grey0},
  }

  if (!certData) {
    return null
  }

  if (!showMisisng) {
    delete dataInOrder.missing
  }

  const labels = consumption.map(({name}) => name) || []

  const lines: ILine[] = []

  if (showExcess) {
    lines.push({
      key: 'excess',
      label: 'Excess',
      color: theme.colors.yellow3,
      data: excess.map(({value}) => value),
      type: ChartType.LINE,
      stackType: StackType.SECONDARY,
      showPoint: true,
      fill: false,
    })
  }

  lines.push({
    key: 'total',
    label: 'Total',
    color: theme.colors.green7,
    data: totalProduction.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: false,
  })

  lines.push({
    key: 'consumption',
    label: 'Consumption',
    color: theme.colors.blue7,
    data: consumption.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: false,
  })

  lines.push(
    ...Object.keys(dataInOrder).map(key => ({
      key: key,
      label: getLineLabel(key),
      color: dataInOrder[key].color,
      data: dataInOrder[key].value.map(({value}) => value),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
    })),
  )

  // Leaving it here for now in case we want to go back, as it was pretty hard to implement
  // lines.push({
  //   key: 'hidden',
  //   label: 'Hidden',
  //   color: 'transparent',
  //   data: consumption.map(({value}) => value),
  //   type: ChartType.LINE,
  //   stackType: StackType.SECOND_STACK,
  //   showPoint: true,
  //   fill: true,
  //   hideTooltip: true,
  //   hidden: true,
  // })

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: true, hideMonthly: true}}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default CertificatesChart
