import {useFormFieldName} from 'components/Form/FormFieldNamePrefixProvider'
import React, {useEffect, useState} from 'react'
import {Loader} from '@googlemaps/js-api-loader'
import PlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-places-autocomplete'
import {OptionTypeBase, StylesConfig} from 'react-select'
import ILocation, {Country} from 'domain/ILocation'
import {getStringifiedLocation} from 'helpers/site'
import useFormContext from 'hooks/useFormContext'
import useLocalization from 'hooks/useLocalization'
import FormAutocompleteSelect from 'components/Form/FormAutocompleteSelect'
import countries from 'components/Map/countries.json'
import FormInput from 'components/Form/FormInput'
import {IProps} from 'components/Map/MapWithHeader'
import styled from 'styled-components'
import Box from 'components/Box'
import ITheme from 'theme/ITheme'
import env from '@beam-australia/react-env'
import useTheme from 'hooks/useTheme'

export const LocationBox = styled(Box)`
  pointer-events: all;
  color: ${props => (props.theme as ITheme).colors.secondary};
`

export interface ILocationSearch {
  placeId: string
  coordinatesLat: number
  coordinatesLon: number
  searchString: string
  countryCode: Country
}

const inputLikeStyles = (theme: ITheme): StylesConfig<OptionTypeBase, boolean> => ({
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.colors.surface,
      borderColor: state.isFocused ? theme.colors.light1 : theme.colors.light1,
      boxShadow: 'unset',
      borderWidth: '1px',
      height: '40px',
      fontSize: '16px',
    }
  },
})

const contrastStyles = (theme: ITheme): StylesConfig<OptionTypeBase, boolean> => ({
  control: provided => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderWidth: '1px',
      borderColor: theme.colors.secondary,
      height: '40px',
      fontSize: '16px',
    }
  },
})

const PLACES_API_STATUS_NO_RESULTS = 'ZERO_RESULTS'

const LocationSearch: React.FC<
  IProps & {
    location: ILocation
    variant?: 'primary' | 'contrast'
    required?: boolean
    placeholder?: string
    className?: string
    isDisabled?: boolean
    setLocation?: (location: ILocationSearch) => void
    allowedCountries?: Country[]
  }
> = ({location, setLocation, variant, placeholder, required, className, isDisabled, allowedCountries}) => {
  const theme = useTheme()
  const [googleLoaded, setGoogleLoaded] = useState<boolean>(false)
  const {translate} = useLocalization()
  const [searchString, setSearchString] = useState<string>(
    location?.searchString || getStringifiedLocation(location, translate) || '',
  )

  const {setValue, getValues} = useFormContext()
  const coordinatesLatField = useFormFieldName('location.coordinatesLat')
  const coordinatesLonField = useFormFieldName('location.coordinatesLon')
  const placeIdField = useFormFieldName('location.placeId')
  const countryCodeField = useFormFieldName('location.countryCode')
  const searchStringField = 'location.search'
  const [isLoadingPlaceDetails, setIsLoadingPlaceDetails] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  const getIso3Country = (result: google.maps.GeocoderResult): Country => {
    const iso2Country = result.address_components?.find(component => component.types.includes('country'))?.short_name
    return countries.find(c => c.iso2 === iso2Country)?.iso3 as Country
  }

  const onPlacesChanged = async searchString => {
    setValue(coordinatesLatField, null)
    setValue(coordinatesLonField, null)
    setValue(placeIdField, null, true)
    setValue(countryCodeField, null)
    setSearchString(searchString || '')
    setValue(searchStringField, searchString || '', true)
  }

  const validateCountry = (country: Country): boolean => {
    if (!allowedCountries?.length) return true

    return country ? allowedCountries.includes(country) : false
  }

  const setPlace = async placeId => {
    setIsLoadingPlaceDetails(true)
    const results = await geocodeByPlaceId(placeId)

    const country = getIso3Country(results[0])

    if (!validateCountry(country)) {
      setError(translate(`%s not supported, allowed countries are: %s`, country, allowedCountries.join(', ')))
      setIsLoadingPlaceDetails(false)

      setSearchString('')
      setValue(searchStringField, null)

      return
    } else {
      setError(null)
    }
    const {lat, lng} = await getLatLng(results[0])
    const formattedAddress = results[0]?.formatted_address

    setValue(coordinatesLatField, lat)
    setValue(coordinatesLonField, lng)
    setValue(placeIdField, placeId)
    setValue(countryCodeField, country)
    setSearchString(formattedAddress)
    setValue(searchStringField, formattedAddress, true)

    setLocation &&
      setLocation({
        coordinatesLat: lat,
        coordinatesLon: lng,
        placeId,
        searchString: results[0]?.formatted_address,
        countryCode: country,
      })

    setIsLoadingPlaceDetails(false)
  }

  const onError = (status, clearSuggestionsFn) => {
    if (status === PLACES_API_STATUS_NO_RESULTS) {
      setIsLoadingPlaceDetails(false)
    }
    clearSuggestionsFn()
  }

  useEffect(() => {
    const loader = new Loader({
      apiKey: env('GOOGLE_API_KEY'),
      id: '__googleMapsScriptId',
      version: 'weekly',
    })

    loader.importLibrary('places').then(() => setGoogleLoaded(true))
  }, [])

  return (
    googleLoaded && (
      <PlacesAutocomplete
        value={searchString}
        onChange={onPlacesChanged}
        debounce={400}
        onError={onError}
        ref={c => {
          if (!c) return
          c.handleInputOnBlur = () => {} // Prevents resetting the suggestions on blur
        }}
      >
        {({getInputProps, getSuggestionItemProps, loading, suggestions}) => {
          const {onChange, value, onKeyDown, ...rest} = getInputProps()

          return (
            <>
              <FormAutocompleteSelect
                {...rest}
                className={className}
                isDisabled={isDisabled}
                isSearch
                onKeyDown={(event: React.KeyboardEvent) => {
                  // override the default event handler with a no-op handler,
                  // otherwise arrow key navigation for suggestions will not work
                }}
                styles={variant && (variant === 'primary' ? inputLikeStyles(theme) : contrastStyles(theme))}
                required={required}
                name="location.search"
                placeholder={placeholder || translate('Search..')}
                defaultInputValue={value}
                onChange={placeId => {
                  setPlace(placeId)
                }}
                onInputChange={value => {
                  const event = {target: {value: value}}
                  onChange(event)
                  setIsLoadingPlaceDetails(true)
                }}
                options={suggestions.map(suggestion => ({
                  value: suggestion.placeId,
                  label: suggestion.description,
                }))}
                closeMenuOnScroll={e => e}
                isLoading={isLoadingPlaceDetails || loading}
                customError={error}
                validate={(value, getValues) => {
                  const values = getValues()
                  const placeId = values?.['location.placeId']

                  if (!placeId) {
                    return translate('Location should be selected from choices')
                  }

                  return true
                }}
              />
              <FormInput name="location.placeId" type="hidden" defaultValue={null} />
              <FormInput name="location.coordinatesLat" type="hidden" defaultValue={null} />
              <FormInput name="location.coordinatesLon" type="hidden" defaultValue={null} />
              <FormInput name="location.countryCode" type="hidden" defaultValue={null} />
            </>
          )
        }}
      </PlacesAutocomplete>
    )
  )
}

export default LocationSearch
