import React, {useMemo, useState} from 'react'
import {useAtomValue, useSetAtom} from 'jotai'
import {refetchSitesDataAtom} from 'atoms/portfolioSites'
import {rootServiceAtom} from 'atoms/general'
import ResponsiveGrid from 'components/ResponsiveGrid'
import {ScreenBreakpoint} from 'hooks/useScreenSize'
import FormInput from 'components/Form/FormInput'
import SiteStateSelect from './Form/SiteStateSelect'
import FormTextEditor from 'components/Form/FormTextEditor'
import FormTextArea from 'components/Form/FormTextArea'
import LocationSearch from './LocationSearch'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import DownloadExampleLink from './Form/DownloadExampleLink'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import Label from 'components/Label'
import FormSelect from 'components/Form/FormSelect'
import {DateFormat, formatDate, generateYears, getLastFullMonth, getMonths, getPrevMonthYear} from 'helpers/date'
import {MeterRefKind, ProfileUploadKind} from 'services/V2ApiErrorMapper'
import {partyAtom} from 'atoms/party'
import useLocalization from 'hooks/useLocalization'
import {useHistory} from 'react-router'
import Form from 'components/Form'
import Page from 'components/Page'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import SiteMap from 'components/Platform/Map/SiteMap'
import InnerScroll from 'components/InnerScroll'
import Box from 'components/Box'
import Heading from 'components/Heading'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import useTabs from 'hooks/useTabs'
import useAlerts from 'hooks/useAlerts'

const TAB_SLUG = 'profile'
const UPLOAD_TAB = 'upload'

interface IProps extends React.PropsWithChildren {}

const BuyerSiteCreatePage: React.FC<IProps> = () => {
  const [interval, setInterval] = useState(() => {
    const lastFullMonth = getLastFullMonth()
    const lastFullMonthYear = getPrevMonthYear()
    return {
      month: lastFullMonth,
      year: lastFullMonthYear,
    }
  })
  const {currentTab} = useTabs(TAB_SLUG)
  const {translate} = useLocalization()
  const rootService = useAtomValue(rootServiceAtom)
  const {addSuccess, addError} = useAlerts()
  const party = useAtomValue(partyAtom)
  const history = useHistory()
  const refetchSitesData = useSetAtom(refetchSitesDataAtom)
  const uploadTab = currentTab === UPLOAD_TAB

  const months = getMonths()
  const availableMonths = useMemo(() => {
    const lastFullMonth = getLastFullMonth()
    const lastFullMonthYear = getPrevMonthYear()

    if (interval.year === lastFullMonthYear) {
      // For current year, only show months up to last full month
      return months.slice(0, months.indexOf(lastFullMonth) + 1)
    }
    return months
  }, [interval.year])

  const years = useMemo(() => {
    const lastFullMonthYear = getPrevMonthYear()
    return generateYears(lastFullMonthYear, 3)
  }, [])

  const handleSubmit = async (data, {setError}) => {
    try {
      const {location, file, operationsStart, operationsEnd, builder, interval, ...rest} = data

      const payload = {
        ...rest,
        placeId: location.placeId,
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        operationsStart: operationsStart ? formatDate(operationsStart, DateFormat.YEAR_MONTH_DAY) : undefined,
        timeseries: {
          kind: uploadTab ? TimeseriesKind.FILE_UPLOAD : TimeseriesKind.BUILDER,
          payload: {
            hourlyVolumes: uploadTab ? undefined : builder.hourlyVolumes,
            attachmentId: uploadTab ? file.id : undefined,
          },
        },
      }

      await rootService.v2ContentService.createBuyerSite(party.id, payload)
      refetchSitesData()
      addSuccess('Site successfully created')
      history.push('/consumer/portfolio/sites?sites=list')
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in ProfileUploadKind) {
        setError('file', 'file', e.message)
        addError(e.message)
      } else if (kind in MeterRefKind) {
        setError('meterReference', 'meterReference', e.message)
        addError(e.message)
      } else {
        addError('Failed to save site', e?.correlationId, e?.message)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('Add site')}
        description={translate('Manage your site')}
        noDivider
        corner={<FormSubmitButton>{translate('Save')}</FormSubmitButton>}
        aside={useMemo(
          () => (
            <SiteMap
              title={translate('Site address')}
              defaultContextSite={{
                siteType: SiteType.CONSUMPTION,
              }}
            />
          ),
          [],
        )}
      >
        <InnerScroll noRightPad>
          <ResponsiveGrid
            gap={4}
            templateColumns={{[ScreenBreakpoint.Large]: '1fr', [ScreenBreakpoint.MLarge]: '2fr 3fr'}}
            pad={{bottom: 2}}
          >
            <Box pad={2} border round>
              <Heading pad={{bottom: 1}} size="msmall">
                {translate('General site data')}
              </Heading>
              <FormInput name="name" label={translate('Name')} required defaultValue={null} />
              <FormTextEditor name="description" label={translate('Description')} />
              <Label text={translate('Location')}>
                <LocationSearch
                  location={null}
                  title="Location"
                  variant="primary"
                  placeholder={translate('Start typing the address')}
                  required
                />
              </Label>
              <FormTextArea name="locationDetails" label={translate('Location details')} />
              <FormInput
                name="meterReference"
                minLength={6}
                maxLength={25}
                placeholder={translate('Insert meter reference number')}
                label={translate('Meter reference number')}
                required
                defaultValue={null}
              />
              <SiteStateSelect name="state" required />
              <FormInput type="date" name="operationsStart" label={translate('Operations start')} defaultValue={null} />
            </Box>
            <Box pad={{top: 2, horizontal: 2}} border round>
              <Heading pad={{bottom: 1}} size="msmall">
                {translate('Consumption data')}
              </Heading>
              <Label text={translate('Interval')}>
                <Box direction="row" gap={1} pad={{bottom: 2}}>
                  <FormSelect
                    required
                    name="interval.month"
                    defaultValue={interval.month}
                    onChange={value => setInterval({...interval, month: value})}
                    margin={0}
                    options={availableMonths.map(month => ({value: month, label: month}))}
                    placeholder="Month"
                  />
                  <FormSelect
                    required
                    defaultValue={interval.year}
                    name="interval.year"
                    onChange={value => setInterval({...interval, year: +value})}
                    margin={0}
                    options={years.map(year => ({value: year, label: `${year}`}))}
                    placeholder="Year"
                  />
                </Box>
              </Label>
              <Tabs name={TAB_SLUG} margin={{top: 0}}>
                <Tab label={translate('Build')} slug="">
                  <Box pad={{bottom: 2}}>
                    <FormProfileBuilder name="builder" />
                  </Box>
                </Tab>
                <Tab label={translate('Upload')} slug={UPLOAD_TAB}>
                  <FormAttachmentUpload
                    label={translate('Upload consumption profile')}
                    name="file"
                    removable
                    required
                    coloredLink
                  />
                  <Box margin={{vertical: 2}}>
                    <DownloadExampleLink interval={interval} siteType={SiteType.CONSUMPTION} />
                  </Box>
                </Tab>
              </Tabs>
            </Box>
          </ResponsiveGrid>
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default BuyerSiteCreatePage
