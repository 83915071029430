import React from 'react'
import useTheme from 'hooks/useTheme'
import Chart, {ChartType, ILine, StackType, getMaxValue, getMinValue} from 'components/Chart'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'

interface IProps extends React.PropsWithChildren {
  timeseries: Array<{name: string; value: number}>
  siteName: string
}

const SiteSummaryChart: React.FC<IProps> = ({timeseries, siteName}) => {
  const theme = useTheme()

  if (!timeseries) {
    return null
  }

  const labels = timeseries.map(({name}) => name) || []

  const lines: ILine[] = []

  lines.push({
    key: 'siteTotal',
    label: siteName,
    color: theme.colors.blue7,
    data: timeseries.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.DEFAULT,
    fill: false,
    showPoint: true,
  })

  return (
    <Chart
      toolbar={{vertical: false, timescale: {hideYearly: true, hideMonthly: true}}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default SiteSummaryChart
